export const PROFIL_GET = "PROFIL_GET"
export const ABOUTUS_GET = "ABOUTUS_GET"
export const SERVICES_GET = "SERVICES_GET"
export const CERTIFICATES_GET ="CERTIFICATES_GET"
export const PASSWORD_GET = "PASSWORD_GET"
export const EDUCATION_GET = "EDUCATION_GET"


export const PROFIL_SET = "PROFIL_SET"
export const ABOUTUS_SET = "ABOUTUS_SET"
export const SERVICES_SET = "SERVICES_SET"
export const CERTIFICATES_SET ="CERTIFICATES_SET"
export const PASSWORD_SET = "PASSWORD_SET"
export const EDUCATION_SET = "EDUCATION_SET"
