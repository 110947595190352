import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import { API_SERVICE_UPDATE } from "ApiConfig";
import { jwtDecode as jwtDecode } from 'jwt-decode';
import {
    JOBS_BOOKMARKS_GET,
  
        } from "./actionTypes"

import { Jobs_bookmarks_SET } from "./actions";







function* BookmarksJobs ({BookmarkData}) {



   async function API() {
       var partner = jwtDecode(localStorage.getItem("authUser"))
       var id = partner.partner_id
 
       const response = await axios
          .post(`${API_SERVICE_UPDATE}JobsBookmarks`, {
            token: JSON.parse(localStorage.getItem("authUser")), 
            partner_id: id,
            bookmark:BookmarkData.Bookmark,
            status:BookmarkData.status
          })
          .then(response => response)
          .catch(err => {
             console.log("Bitmedi")
             // LoginButtonStatus(dispatch,"a")
          })
 
 
       return response
    }
 
    // Login İşleminde Sunucudan Dönen Yanıt
    const datam = yield call(API)


    yield put(Jobs_bookmarks_SET(datam)) 

}



function* JobsBookmark() {


    yield takeEvery(JOBS_BOOKMARKS_GET, BookmarksJobs)
 }
 
 export default JobsBookmark