import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Dna } from 'react-loader-spinner'
import {
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    Card,
    CardBody,
  } from "reactstrap"

  import { withTranslation } from "react-i18next";

// Redux
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import classnames from "classnames"

import TopBarEdit from "components/Pofile/TopBarEdit";
import Tab_Profil from "components/Pofile/Edit/Tab_Profil";
import Tab_AboutUs from "components/Pofile/Edit/Tab_AboutUs";
import Tab_Services from "components/Pofile/Edit/Tab_Services";
import Tab_Certificates from "components/Pofile/Edit/Tab_Certificates";
import Tab_Education from "components/Pofile/Edit/Tab_Education";
//Import Images
import img1 from "../../assets/images/product/img-1.png"
import img7 from "../../assets/images/product/img-7.png"

import { useDispatch } from "react-redux";

const ProfileEdit = props => {


  const dispatch = useDispatch();


  const [ProfilData , setProfilData] = useState()

  const [activeTab, setactiveTab] = useState("1")


  useEffect(() => {
    dispatch({ type: 'API_DASHBOARD_GET' })


  },[])


  useEffect(() => {
    if (props) {
      setProfilData(props.data);
    }
  }, [props]);




   const  toggleTab = (tab) => {
        if (activeTab !== tab) {
         setactiveTab(tab)
        }
      }


  
      const PageTracker = (tracker) => {
       
      }
    



      return (
        <React.Fragment>
           {ProfilData ? 
          <div className="page-content">
            <Container fluid>
             
              <Breadcrumb title="Wawy" breadcrumbItem="Profile Edit" />
              <Row><Col lg="8"></Col><Col lg="4" ><TopBarEdit /></Col></Row>
            <Row>
              <Col lg="12">
                
              <div className="checkout-tabs">
                <Row>
                  <Col lg="2" sm="3">
                    <Nav className="flex-column" pills>
                      <NavItem  onMouseOver={() => PageTracker("Profil-Edit")}>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1")
                          }}
                        >
                          <i className="bx bx-user-pin d-block check-nav-icon mt-4 mb-2" />
                          <p className="fw-bold mb-4">Profil </p>
                        </NavLink>
                      </NavItem>
                      <NavItem  onMouseOver={() =>PageTracker("Edit-AboutUs")}>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2")
                          }}
                        >
                          <i className="bx bx-text d-block check-nav-icon mt-4 mb-2" />
                          <p className="fw-bold mb-4">AboutUs </p>
                        </NavLink>
                      </NavItem>
                      <NavItem   onMouseOver={() => PageTracker("Edit-Services")}>
                        <NavLink
                          className={classnames({
                            active: activeTab === "3",
                          })}
                          onClick={() => {
                            toggleTab("3")
                          }}
                        >
                          <i className="bx bx-customize d-block check-nav-icon mt-4 mb-2" />
                          <p className="fw-bold mb-4">Services</p>
                        </NavLink>
                      </NavItem>
                      <NavItem   onMouseOver={() => PageTracker("Edit-Certificates")}>
                        <NavLink
                          className={classnames({
                            active: activeTab === "4",
                          })}
                          onClick={() => {
                          toggleTab("4")
                          }}
                        >
                          <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
                          <p className="fw-bold mb-4">Certificates</p>
                        </NavLink>
                      </NavItem>

                      <NavItem   onMouseOver={() => PageTracker("Edit-Education")}>
                        <NavLink
                          className={classnames({
                            active: activeTab === "5",
                          })}
                          onClick={() => {
                          toggleTab("5")
                          }}
                        >
                          <i className="bx bx-buildings d-block check-nav-icon mt-4 mb-2" />
                          <p className="fw-bold mb-4">Education</p>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                  <Col lg="10" sm="9">
                    <Card>
                      <CardBody>
                        <TabContent activeTab={activeTab}>
                          <Tab_Profil info={ProfilData} />
                          <Tab_AboutUs info={ProfilData}  />
                          <Tab_Services info={ProfilData}  />
                          <Tab_Certificates info={ProfilData}  />     
                          <Tab_Education info={ProfilData}  />         
                        </TabContent>
                      </CardBody>
                    </Card>
            
                  </Col>
                </Row>
              </div>
  
  
              </Col>
            </Row>
  
  
            </Container>
          </div> : <div className="page-content">
        <Container fluid>


          <Row style={{ height: "100vh" }} >
            <Col lg="12">

            </Col>
            <Row>
              <Col lg="4"></Col>
              <Col lg="4" className="text-center">
                <Dna
                  visible={true}
                  height="160"
                  width="160"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"
                />
              </Col>
              <Col lg="4"></Col>


            </Row>
            <Col lg="12">

            </Col>
          </Row>

        </Container>
      </div> }
        </React.Fragment>
      );

}






const mapStateToProps = (state) => {
  return {
    data: state.Dashboard.action.datam,

  };
};


export default connect(mapStateToProps )((withTranslation())(ProfileEdit))


ProfileEdit.propTypes = {
  history: PropTypes.object,
};


