import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';


//import images
import img1 from "../../assets/images/companies/img-1.png";
import img4 from "../../assets/images/companies/img-4.png";
import img5 from "../../assets/images/companies/img-5.png";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";



class AboutUs extends Component {

    
    render() {
        
        return (
            <React.Fragment>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            
                            <h5 className="mb-3">About Us</h5>
                            <p  className="text-muted"> {  this.props.info ? 
                            
                            <div dangerouslySetInnerHTML={{ __html:this.props.info.aboutus.about }}></div>
                            : null }</p>

                        </CardBody>
                    </Card>




                </Col>
            </React.Fragment>
        );
    }

}

export default AboutUs;

/*
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody className="border-bottom">
                                    <h5 className="mb-3">Social Media</h5>
                                    <div className="hstack gap-2">
                                        <Link to="#!" className="btn btn-soft-primary"><i className="bx bxl-facebook align-middle me-1"></i> Facebook </Link>
                                        <Link to="#!" className="btn btn-soft-info"><i className="bx bxl-twitter align-middle me-1"></i> Twitter</Link>
                                        <Link to="#!" className="btn btn-soft-pink"><i className="bx bxl-instagram align-middle me-1"></i> Instagram</Link>
                                        <Link to="#!" className="btn btn-soft-success"><i className="bx bxl-whatsapp align-middle me-1"></i> Whatsapp</Link>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

*/