import { 
  DASHBOARD_NEWJOBS_GET,
  DASHBOARD_NEWJOBS_SET,
  JOBS_GET,
  JOBS_SET,
  JOBS_BOOKMARKS_GET,
  JOBS_BOOKMARKS_SET ,
  JOBS_VIEW_GET,
  JOBS_VIEW_SET 
      } from "./actionTypes"



export const Dashboard_NewJobs_GET =  JobsData => ({
  type:DASHBOARD_NEWJOBS_GET,
  JobsData
})

export const Dashboard_NewJobs_SET =  JobsData => ({
  type:DASHBOARD_NEWJOBS_SET,
  JobsData
})

export const Jobs_GET = JobsData => ({
  type:JOBS_GET,
  JobsData,
})

export const Jobs_SET = JobsData => ({
  type:JOBS_SET,
  JobsData,
})


