import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

const Sidebar = ({info}) => {




    return (
        <React.Fragment>
            <Col lg={3}>
                <Card>
                    <CardBody>
                        <ul className="list-unstyled vstack gap-3 mb-0">
                            <li>
                                <div className="d-flex">
                                    <i className='bx bx-calendar font-size-18 text-primary'></i>
                                    <div className="ms-3">
                                        <h6 className="mb-1 fw-semibold">Days:</h6>
                                        <span className="text-muted">{info.days}</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex">
                                    <i className='bx bx-money font-size-18 text-primary'></i>
                                    <div className="ms-3">
                                        <h6 className="mb-1 fw-semibold">Current Price:</h6>
                                        <span className="text-muted">£{info.fee.fee}/hour</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex">
                                    <i className='bx bx-money font-size-18 text-primary'></i>
                                    <div className="ms-3">
                                        <h6 className="mb-1 fw-semibold">Daily hour:</h6>
                                        <span className="text-muted">
                                        {
                                            info.hoursArray
                                                .filter(item => item.checked === true)
                                                .map(item => (
                                               
                                                    <div key={item.name}>
                                                 
                                                        <span className="text-muted">{item.name}</span>
                                                   
                                                    </div>
                                              
                                                ))
                                            }
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex">
                                    <i className='bx bx-user font-size-18 text-primary'></i>
                                    <div className="ms-3">
                                        <h6 className="mb-1 fw-semibold">Days:</h6>
                                        {
                                            info.daysArray
                                                .filter(item => item.checked === true)
                                                .map(item => (
                                               
                                                    <div key={item.name} className='flex'>
                                                 
                                                        <span className="text-muted">{item.name}</span>
                                                   
                                                    </div>
                                              
                                                ))
                                            }
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex">
                                    <i className='mdi mdi-book-education font-size-18 text-primary'></i>
                                    <div className="ms-3">
                                        <h6 className="mb-1 fw-semibold">Requirements:</h6>
                                        <span className="text-muted">
                                        {
                                            info.requirements
                                                .map(item => (
                                               
                                                    <div key={item.name} className='flex'>
                                                 
                                                        <span className="text-muted">{item.name}</span>
                                                   
                                                    </div>
                                              
                                                ))
                                            }
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex">
                                    <i className='mdi mdi-google-translate font-size-18 text-primary'></i>
                                    <div className="ms-3">
                                        <h6 className="mb-1 fw-semibold">Work Type:</h6>
                                        <span className="text-muted">
                                        {
                                            info.worktimeArray
                                                .filter(item => item.checked === true)
                                                .map(item => (
                                               
                                                    <div key={item.name} className='flex'>
                                                 
                                                        <span className="text-muted">{item.name}</span>
                                                   
                                                    </div>
                                              
                                                ))
                                            }
                                        </span>
                                    </div>
                                </div>
                            </li>
                         {/*  <li className="hstack gap-2 mt-3">
                                <Link to="#!" className="btn btn-soft-primary w-100">Hire Now</Link>
                                <Link to="#!" className="btn btn-soft-danger w-100">Contact Us</Link>
                            </li>
                        */} 
                        </ul>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default Sidebar;