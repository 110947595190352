import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container , Col,
  Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { connect } from 'react-redux';


import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import InviteFriends from "./InviteFriends";
import Comments from "components/Dashboard/Comments";
import { Dna } from 'react-loader-spinner'
import LastJobs from "./LastJobs";




const Dashboard = (props) => {

  const dispatch = useDispatch();
  const [dashboardData , setdashboardData] = useState()

  useEffect(() => {
    dispatch({ type: 'API_DASHBOARD_GET' })
    
  },[])


  useEffect(() => {
    if (props) {
      setdashboardData(props.data);
    }
  }, [props]);

 

  //meta title
  document.title = "Dashboard | The Wawy - Partner Dashboard ";

  return (
    <React.Fragment>
     
    {!dashboardData ?
      <div className="page-content">
        <Container fluid>


          <Row style={{ height: "100vh" }} >
            <Col lg="12">

            </Col>
            <Row>
              <Col lg="4"></Col>
              <Col lg="4" className="text-center">
                <Dna
                  visible={true}
                  height="160"
                  width="160"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"
                />
              </Col>
              <Col lg="4"></Col>


            </Row>
            <Col lg="12">

            </Col>
          </Row>

        </Container>
      </div>
      :
      <div className="page-content">
        <Container fluid>

          <h4>Dashboard</h4>


          <Row >
            <Col lg="4">

              <WelcomeComp />
            
              <InviteFriends />
              <Comments comments={dashboardData.comments} />
            </Col>
            <Col xl="8">
              <Row>
                <LastJobs />

              </Row>
            </Col>
          </Row>




        </Container>
      </div>

    }
  </React.Fragment>
  );
};


const mapStateToProps = (state) => {
  return {
    data: state.Dashboard.action.datam,

  };
};


export default connect(mapStateToProps )((withTranslation())(Dashboard))


Dashboard.propTypes = {
  history: PropTypes.object,
};




