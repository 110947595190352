import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik, Field } from "formik";

// action
import { registerPartner, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withRouter from "components/Common/withRouter";
// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";






const Register = props => {

  //meta title
  document.title = "Register | The Wawy - Partner Dashboard ";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullname:"",
      email:"",
      phone:"",
      password:"",
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required("Please Enter Your Full Name"),
      email: Yup.string().required("Please Enter Your Email"),
      phone: Yup.string().required("Please Enter Your Phone"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {

      dispatch(registerPartner(values, props.router.navigate));
    
    }
  });

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));


  

  useEffect(() => {
    dispatch(apiError(""));
    const referenceCode = window.location.pathname.substring(10 ,46);
    if(referenceCode){localStorage.setItem('ReferenceCode', referenceCode)} else {localStorage.setItem('ReferenceCode', '1000')}
  }, []);



 


  return (
    
    <React.Fragment>


<ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover

/>

      <div className="home-btn d-none d-sm-block">
      <Link to="https://thewawy.co.uk" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Free Register</h5>
                        <p>Get your free The wawy account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                     

                     {user ? 
                     
                     user.status==0  ? (
                      <Alert color="danger">{user.message}</Alert>
                    ) : <Alert color="success">{user.message}</Alert>
                     
                     : null}

                      <div className="mb-3">
                        <Label className="form-label">Full Name</Label>
                        <Input
                          id="fullname"
                          name="fullname"
                          className="form-control"
                          placeholder="Enter fullname"
                          type="fullname"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.fullname || ""}
                          invalid={
                            validation.touched.fullname && validation.errors.fullname ? true : false
                          }
                        />
                        {validation.touched.fullname && validation.errors.fullname ? (
                          <FormFeedback type="invalid">{validation.errors.fullname}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Phone</Label>
                        <PhoneInput
                          inputProps={{
                            name: 'phone',
                          
                           
                          }}
                     
                              country={'gb'}
                              name= 'phone'
                              onChange={(value, ...props) => {
                               
                                validation.setFieldValue("phone", value); // formik form alanına değer set etme
                                // burada yapılacak işlemler, alınan değerin formatlanması vb. gibi işlemler yapılabilir.
                              }}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone || ""}
                          invalid={
                            validation.touched.phone && validation.errors.phone ? true : false
                          }
                          inputStyle={{height: "40px",
                            width: "100%"}}
                            />

                     
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          type="email"
                          placeholder="Enter email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the The Wawy{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} The Wawy. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Octillionsoft LIMITED
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};



export default withRouter(Register);

Register.propTypes = {
  history: PropTypes.object,
};
