import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import { API_SERVICE_QUERY, API_SERVICE_UPDATE } from "ApiConfig";
import { jwtDecode as jwtDecode } from 'jwt-decode';
import { 
    DASHBOARD_NEWJOBS_GET,
    DASHBOARD_NEWJOBS_SET,
    JOBS_GET,
    JOBS_SET,
    JOBS_VIEW_GET 
        } from "./actionTypes"

import { Dashboard_NewJobs_SET, Jobs_SET, Jobs_view_SET } from "./actions";



function* NewJobs ({JobsData}) {


    async function API() {
        var partner = jwtDecode(localStorage.getItem("authUser"))
        var id = partner.partner_id
  
        const response = await axios
           .post(`${API_SERVICE_QUERY}Jobs`, {
              partner_id: id,
              token: JSON.parse(localStorage.getItem("authUser"))
           })
           .then(response => response)
           .catch(err => {
              console.log("Bitmedi")
              // LoginButtonStatus(dispatch,"a")
           })
  
  
        return response.data
     }
  
     // Login İşleminde Sunucudan Dönen Yanıt
     const datam = yield call(API)

  
     yield put(Dashboard_NewJobs_SET(datam)) 

}


function* Jobs ({JobsData}) {
    
    async function API() {
        var partner = jwtDecode(localStorage.getItem("authUser"))
        var id = partner.partner_id
  
        const response = await axios
           .post(`${API_SERVICE_QUERY}Jobs`, {
              partner_id: id,
              token: JSON.parse(localStorage.getItem("authUser"))
           })
           .then(response => response)
           .catch(err => {
              console.log("Bitmedi")
              // LoginButtonStatus(dispatch,"a")
           })
  
  
        return response.data
     }
  
     // Login İşleminde Sunucudan Dönen Yanıt
     const datam = yield call(API)
  
  
     yield put(Jobs_SET(datam)) 
}





function* AllJobs() {

    yield takeEvery(DASHBOARD_NEWJOBS_GET, NewJobs)
    yield takeEvery(JOBS_GET, Jobs)


 }
 
 export default AllJobs