import PropTypes from "prop-types";
import React, {  useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

// Redux
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";
//i18n
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import { Dna } from 'react-loader-spinner'
import TopBar from "components/Pofile/TopBar";
import Welcome from "components/Pofile/Welcome";
import Services from "components/Pofile/Services";
import Stats from "components/Pofile/Stats";
import AboutUs from "components/Pofile/AboutUs";
import Education from "components/Pofile/Educations";
import CertificatesCard from "components/Pofile/CertificatesCard";
import Comments from "components/Pofile/Comments";



const UserProfile =  (props) => {

  const [ProfilData, setPorfilData] = useState()
  const dispatch = useDispatch();

  useEffect(() => {
   dispatch({ type: 'API_DASHBOARD_GET' })

  },[])

  useEffect(() => {
     if (props) {
      setPorfilData(props.data)
    }
  },[props])


  return (
    
    <React.Fragment>
      {ProfilData ? 
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="The Wawy" breadcrumbItem="Profile" />
        <Row><Col lg="8"></Col><Col lg="4" ><TopBar /></Col></Row>
        <Row>
         
        <Col lg="4">
        <Welcome info={ProfilData} />
        <Services info={ProfilData}  />
        <Comments info={ProfilData}  />
         </Col>
    <Col lg="8">
        <Stats info={ProfilData}  />
        <AboutUs  info={ProfilData} />
        <Education  info={ProfilData} />
         <CertificatesCard  info={ProfilData} />
     </Col>  
        
        </Row>


        </Container>
      </div>  : 
            <div className="page-content">
            <Container fluid>
    
    
              <Row style={{ height: "100vh" }} >
                <Col lg="12">
    
                </Col>
                <Row>
                  <Col lg="4"></Col>
                  <Col lg="4" className="text-center">
                    <Dna
                      visible={true}
                      height="160"
                      width="160"
                      ariaLabel="dna-loading"
                      wrapperStyle={{}}
                      wrapperClass="dna-wrapper"
                    />
                  </Col>
                  <Col lg="4"></Col>
    
    
                </Row>
                <Col lg="12">
    
                </Col>
              </Row>
    
            </Container>
          </div>
      
      }
    </React.Fragment>
  );

}



const mapStateToProps = (state) => {
  return {
    data: state.Dashboard.action.datam,

  };
};


export default connect(mapStateToProps )((withTranslation())(UserProfile))


UserProfile.propTypes = {
  history: PropTypes.object,
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func,
};









/*
            <h4 className="card-title mb-4">Change User Name</h4>

            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    username: (this.state && this.state.name) || "",
                    idx: (this.state && this.state.idx) || "",
                  }}
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required(
                      "Please Enter Your Username"
                    ),
                  })}
                  onSubmit={values => {
                    this.props.editProfile(values);
                  }}
                >
                  {({ errors, status, touched }) => (
                    <Form className="form-horizontal">
                      <div className="mb-3">
                        <Label for="username" className="form-label">
                          Username
                        </Label>
                        <Field
                          name="username"
                          type="text"
                          className={
                            "form-control" +
                            (errors.username && touched.username
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="invalid-feedback"
                        />
                        <Field
                          name="idx"
                          type="hidden"
                          className={
                            "form-control" +
                            (errors.idx && touched.idx
                              ? " is-invalid"
                              : "")
                          }
                        />
                      </div>
                      <div className="text-center mt-4">
                        <Button type="submit" color="danger">
                          Update User Name
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>

*/