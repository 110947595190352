import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import { API_SERVICE_UPDATE } from "ApiConfig";
import { jwtDecode as jwtDecode } from 'jwt-decode';
import {
   PROFIL_GET,
   ABOUTUS_GET,
   SERVICES_GET,
   CERTIFICATES_GET,
   PASSWORD_GET,
   EDUCATION_GET
} from "./actionTypes";

import {
   Profil_SET,
   Aboutus_SET,
   Services_SET,
   Certificates_SET,
   Password_SET,
   Education_SET
} from "./actions"




function* Profil({ data }) {

   async function API() {
      var partner = jwtDecode(localStorage.getItem("authUser"))
      var id = partner.partner_id

      const response = await axios
         .post(`${API_SERVICE_UPDATE}UpdateProfil`, {
            partner_id: id,
            fullname: data.fullname,
            gender: data.gender,
            email: data.email,
            address: data.address,
            phone: data.phone,
            city: data.city,
            county: data.county,
            postcode: data.postcode,
            cigarette: data.cigarette,
            alcohol: data.alcohol,
            token: JSON.parse(localStorage.getItem("authUser")),
         })
         .then(response => response)
         .catch(err => {
            console.log("Bitmedi")
            // LoginButtonStatus(dispatch,"a")
         })

    
      return response.data
   }

   // Login İşleminde Sunucudan Dönen Yanıt
   const datam = yield call(API)

   yield put(Profil_SET(datam))

  
}


function* Aboutus({ data }) {
   async function API() {
      var partner = jwtDecode(localStorage.getItem("authUser"))
      var id = partner.partner_id

      const response = await axios
         .post(`${API_SERVICE_UPDATE}UpdateAboutus`, {
            partner_id: id,
            about: data.about,
            summary: data.summary,
            token: JSON.parse(localStorage.getItem("authUser")),
         })
         .then(response => response)
         .catch(err => {
            console.log("Bitmedi")
            // LoginButtonStatus(dispatch,"a")
         })


      return response.data
   }

   // Login İşleminde Sunucudan Dönen Yanıt
   const datam = yield call(API)

   yield put(Aboutus_SET(datam))
}

function* Services({data }) {
   
   const x = data.x;


   if(x==0) {

      async function API() {
         var partner = jwtDecode(localStorage.getItem("authUser"))
         var id = partner.partner_id
   
         const response = await axios
            .post(`${API_SERVICE_UPDATE}UpdateServices`, {
               partner_id: id,
               x:0,
               name:data.name,
               mission:data.data,
               token: JSON.parse(localStorage.getItem("authUser")),
            })
            .then(response => response)
            .catch(err => {
               console.log("Bitmedi")
               // LoginButtonStatus(dispatch,"a")
            })
   
    
         return response.data
      }
   
      // Login İşleminde Sunucudan Dönen Yanıt
      const datam = yield call(API)
   
      yield put(Services_SET(datam))

   }
   else {
      async function API() {
         var partner = jwtDecode(localStorage.getItem("authUser"))
         var id = partner.partner_id
   
         const response = await axios
            .post(`${API_SERVICE_UPDATE}UpdateServices`, {
               partner_id: id,
               x:1,
               name:data.name,
               mission:data.data,
               token:JSON.parse(localStorage.getItem("authUser")),
            })
            .then(response => response)
            .catch(err => {
               console.log("Bitmedi")
               // LoginButtonStatus(dispatch,"a")
            })
   
    
         return response.data
      }
   
      // Login İşleminde Sunucudan Dönen Yanıt
      const datam = yield call(API)
   
      yield put(Services_SET(datam))
   }


      
}

function* Certificates({ data }) {
   

   async function API() {
      var partner = jwtDecode(localStorage.getItem("authUser"))
      var id = partner.partner_id

      const response = await axios
         .post(`${API_SERVICE_UPDATE}UpdateCertificates`, {
            partner_id: id,
            status: data.status,
            id: data.id,
            token: JSON.parse(localStorage.getItem("authUser")),
         })
         .then(response => response)
         .catch(err => {
            console.log("Bitmedi")
            // LoginButtonStatus(dispatch,"a")
         })


      return response.data
   }

   // Login İşleminde Sunucudan Dönen Yanıt
   const datam = yield call(API)
   

   yield put(Certificates_SET(datam)) 
}

function* Password({ data }) {
   
}







function* Education({ data }) {

   async function API() {
      var partner = jwtDecode(localStorage.getItem("authUser"))
      var id = partner.partner_id

      const response = await axios
         .post(`${API_SERVICE_UPDATE}UpdateEducation`, {
            partner_id: id,
            HighSchoolName: data.HighSchoolName,
            StartTime: data.StartTime,
            FisihTime: data.FisihTime,
            University: data.University,
            UniStartTime: data.UniStartTime,
            UniFisihTime: data.UniFisihTime,
            UniDepartment: data.UniDepartment,
            token: JSON.parse(localStorage.getItem("authUser")),
         })
         .then(response => response)
         .catch(err => {
            console.log("Bitmedi")
            // LoginButtonStatus(dispatch,"a")
         })


    
      return response.data
   }

   // Login İşleminde Sunucudan Dönen Yanıt
   const datam = yield call(API)

   yield put(Education_SET(datam))

  
}




function* AllProfil() {

   yield takeEvery(PROFIL_GET, Profil)
   yield takeEvery(ABOUTUS_GET, Aboutus)
   yield takeEvery(SERVICES_GET, Services)
   yield takeEvery(CERTIFICATES_GET, Certificates)
   yield takeEvery(CERTIFICATES_GET, Certificates)
   yield takeEvery(EDUCATION_GET, Education)
}

export default AllProfil



