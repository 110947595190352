
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import React, {useEffect } from "react";

import profileImg from "../../assets/images/profile-img.png";

import {useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { PROFIL_IMG } from "ApiConfig";
const WelcomeComp = props => {

  const dispatch = useDispatch();

  

  useEffect(() =>{
    dispatch({ type: 'API_DASHBOARD_GET' })
  },[props.data])

  const { data } = useSelector((state) => ({
    data: state.Dashboard.action.datam,

  }));

  var profilImg = "";
if(data) {var profilImg = data.profilimage.picture_path;} 

    return (
      <React.Fragment>
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft">
            <Row>
              <Col xs="7">
                <div className="text-primary p-3">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p>WAWY Dashboard</p>
                </div>
              </Col>
              <Col xs="5" className="align-self-end">
                <img src={profileImg} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row className="flex ">
              <Col sm="4">
                <div className="avatar-md profile-user-wid mb-4">
                  <img
                   src={`${PROFIL_IMG}${profilImg}`}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 className="font-size-15 text-truncate">{data.fullName}</h5>
                <p className="text-muted mb-0 text-truncate">HealtCare</p>
              </Col>
{/* 
              <Col sm="8">
                <div className="pt-4">
                  <Row>
                    <Col xs="6">
                      <h5 className="font-size-15">1</h5>
                      <p className="text-muted mb-0">Job Applied</p>
                    </Col>
                    <Col xs="6">
                      <h5 className="font-size-15">1</h5>
                      <p className="text-muted mb-0">Accepted</p>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Link
                      to={"/Profile"}
                      className="btn btn-primary btn-sm"
                    >
                      View Profile {" "}<i className="mdi mdi-arrow-right ms-1"/>
                    </Link>
                  </div>
                </div>
              </Col>
    */}



                     <Col sm="8" className=" ">
              
          
                  <div className=" float-right mt-[80px]">
                    <Link
                      to={"/Profile"}
                      className="btn btn-primary btn-sm"
                    >
                      View Profile {" "}<i className="mdi mdi-arrow-right ms-1"/>
                    </Link>
                  </div>
               
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
 
}


export default WelcomeComp

