import React, { useState, useEffect} from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useSelector, useDispatch } from "react-redux";
import { Inform_API } from "store/inform/actions";



import leader from "../../../assets/lottie/leader3.json"; // Lottie JSON dosyasının yolu
import accept from "../../../assets/lottie/accept4.json"; // Lottie JSON dosyasının yolu
import announcement from "../../../assets/lottie/new3.json"; // Lottie JSON dosyasının yolu
import Lottie from "lottie-react";



//i18n
import { withTranslation } from "react-i18next";

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

const dispatch = useDispatch();

  useEffect(() => { 
   if(localStorage.getItem("token")) { dispatch(Inform_API());}
   

  }, [dispatch]);



  const inform = useSelector((state) => state.InformReducer.action?.informList?.inform  );

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{inform ?  inform.length  : <></>}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#" className="small">
                  {" "}
                  View All
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>

          {inform ? [...inform].reverse().map((item, key) => {

  return (
    <React.Fragment key={key}> {  
    item.type === "ApplicationApproval" ?    
                                   <Link to="" className="text-reset notification-item">
                                                                                                    <div className="d-flex">
                                                                                                      <div className="avatar-xs me-3">
                                                                                                        <span className="avatar-title  border-stone-950 border-4 bg-white   rounded-circle font-size-16">
                                                                                                        <Lottie
                                                                                  animationData={accept}
                                                                                  loop
                                                                                  autoplay={true}
                                                                                  renderer="svg"
                                                                                  style={{ width: '50px', marginTop:"4px" }}
                                                                              />
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <div className="flex-grow-1">
                                                                                                        <h6 className="mt-0 mb-1">
                                                                                                          {props.t("Job Offer application approved")}
                                                                                                        </h6>
                                                                                                        <div className="font-size-12 text-muted">
                                                                                                          <p className="mb-1">
                                                                                                            {item.title}
                                                                                                          </p>
                                                                                                          <p className="mb-0">
                                                                                                            <i className="mdi mdi-clock-outline" />{" "}
                                                                                                            {item.time}
                                                                                                          </p>
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    </div>
                                                                                                    </Link> 
    
                                            : item.type === "TeamLeader" ?   
                                                                          <Link to="" className="text-reset notification-item">
                                                                          <div className="d-flex ">
                                                                            <div className="avatar-xs me-3">
                                                                              <span className="avatar-title border-stone-950 border-4 bg-white  rounded-circle font-size-16">
                                                                                  <Lottie
                                                                                  animationData={leader}
                                                                                  loop
                                                                                  autoplay={true}
                                                                                  renderer="svg"
                                                                                  style={{ width: '50px', marginTop:"4px" }}
                                                                              />
                                                                              </span>
                                                                            </div>
                                                                            <div className="flex-grow-1">
                                                                              <h6 className="mt-0 mb-1">
                                                                                {"Team Leader"}
                                                                              </h6>
                                                                              <div className="font-size-12 text-muted">
                                                                                <p className="mb-1">
                                                                                {"Congratulations, "}{item.title}
                                                                                </p>
                                                                                <p className="mb-0">
                                                                                  <i className="mdi mdi-clock-outline" />{" "}
                                                                                  {item.time}{" "}
                                                                                </p>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          </Link> 
                                                                          
                                                                          :  item.type === "offer" ?    
                                                                     
                                                                                                      <Link to="" className="text-reset notification-item">
                                                                                                      <div className="d-flex ">
                                                                                                        <div className="avatar-xs me-3">
                                                                                                          <span className="avatar-title border-stone-950 border-4 bg-white  rounded-circle font-size-16">
                                                                                                              <Lottie
                                                                                                              animationData={announcement}
                                                                                                              loop
                                                                                                              autoplay={true}
                                                                                                              renderer="svg"
                                                                                                              style={{ width: '50px', marginTop:"4px" }}
                                                                                                          />
                                                                                                          </span>
                                                                                                        </div>
                                                                                                        <div className="flex-grow-1">
                                                                                                          <h6 className="mt-0 mb-1">
                                                                                                            {"New Offer"}
                                                                                                          </h6>
                                                                                                          <div className="font-size-12 text-muted">
                                                                                                            <p className="mb-1">
                                                                                                            {item?.job_title  }</p>
                                                                                                            <p className="mb-1">
                                                                                                            £{item?.fee.fee } fee {" / "}{item?.fee?.type }{" / "}{item?.fee?.hour }</p>
                                                                                                            <p className="mb-0">
                                                                                                              <i className="mdi mdi-clock-outline" />{" "}
                                                                                                              {item.time}{" "}
                                                                                                            </p>
                                                                                                          </div>
                                                                                                        </div>
                                                                                                      </div>
                                                                                                      </Link> 
                                                                                                    
                                                                                                      
                                                                                                    : <></> }
                                                                              

                                                                                                    </React.Fragment>
        );




      }) : null}
     
             

{/*
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <img
                  src={avatar4}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="flex-grow-1">
                  <h6 className="mt-0 mb-1">Salena Layfield</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "As a skeptical Cambridge friend of mine occidental"
                      ) + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
*/}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/Notification">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};