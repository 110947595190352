import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  TabPane,

  CardTitle,

} from "reactstrap"
import { Editor } from '@tinymce/tinymce-react';
// Redux
import { connect } from "react-redux";

import { Aboutus_GET } from "../../../store/Profil/actions"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//i18n
import { withTranslation } from "react-i18next";


const Tab_AboutUs = props => {

  const dispatch = useDispatch();

  const [about, setabout] = useState(props.info.aboutus.about);
  const [summary, setsummary] = useState(props.info.aboutus.summary);
  const [showToast, setShowToast] = useState(false);


  const handleEditorChange = (about) => {
    setabout(about);
  }

  const handleEditorChangeSummary = (summary) => {
    setsummary(summary);
  }


  // Hakkımızda ve ozet kısımı sagaya gönderiyoruz ve sunucudan gelen yanıtı props atıyoruz.
  const handleSubmit = async () => {

    const datam = { about: about, summary: summary };
    setShowToast(true)
    // Saga.js de işleme alnınan fonksiyon
    await dispatch(Aboutus_GET(datam));


  }


  // Saga'nın props güncellemesinde sonra gelen status durumuna göre uyarı veriyoruz.
  // Not: props.data.data ilk başta undfined döndügü için useEffect içerisinde kullandık. return içerisinde "undfined" degeri döndügü için kullanamadık.
  useEffect(() => {

    // props.data  useEffet de her zaman kontrol edildigi için "props.data" artık güncel oldugu için her sayfa yüklenişinde tekrar çalışıyor ve uyarı veriyor.
    // Bu sorunu düzeltmek için "showToast" adında boolen deerinde bir değişken tanımladık ve işlemlere göre false, true olarak düzenledik.
    if (props.data && showToast) {

      const { status } = props.data.data
      if (status) { toast.success("Aboutus Updated!"); setShowToast(false)} else { toast.warning("Aboutus Not Updated!"); setShowToast(false)  }

    } else {
      console.log("Props data not work : Saga tetiklendi ama props'a veri düşmedi. Yüksek ihtimal sunucu bağlantı sorunu yaşandı.")
      setShowToast(false)
    }
   
  }, [props.data]);




  return (



    <TabPane
      tabId="2"
      id="v-pills-payment"
      role="tabpanel"
      aria-labelledby="v-pills-payment-tab"
    >
      <Row>


        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover

          theme="colored"

        />

        <Col lg="12"><CardTitle className="h4 mb-4 p-1">{" "}ABOUT EDIT</CardTitle></Col>
        <Col lg="12">




          <Editor
            apiKey='9p2wswawjwkdjarnflwvsvj7xvzb81cum3w27fth7bjk2k9x'
            //initialValue={this.props.initialValue}
            value={about}
            onEditorChange={handleEditorChange}

          />



        </Col>

        <hr></hr>
        <hr></hr>
        <Col lg="12"><CardTitle className="h4 mb-4 p-1">{" "}SUMMARY EDIT</CardTitle></Col>
        <Col lg="12">

          <Editor
            apiKey='9p2wswawjwkdjarnflwvsvj7xvzb81cum3w27fth7bjk2k9x'
            //initialValue={this.props.initialValue}
            value={summary}
            onEditorChange={handleEditorChangeSummary}
            init={({
              max_height: 200,
            })}
          />


        </Col>

        <Col lg="12 mt-5">

          {
            // note ?  <div className="content" dangerouslySetInnerHTML={{__html: note}}></div> : ""
          }


        </Col>

      </Row>

      <Row >
        <Col sm="12">
          <div className=" d-grid">
            <button onClick={() => handleSubmit()}
              className="btn btn-primary btn-block "
              type="submit"
            >
              Update
            </button>
          </div>
        </Col>
      </Row>



    </TabPane>
  )



}





const mapStateToProps = (state) => {
  return {
    data: state.Profil.action,

  };
};


export default connect(mapStateToProps)((withTranslation())(Tab_AboutUs))

