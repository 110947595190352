import {
    PROFIL_SET, 
    ABOUTUS_SET,
    SERVICES_SET,
    CERTIFICATES_SET,
    PASSWORD_SET,
  EDUCATION_SET } from "./actionTypes";


const initialState = {
    error:"",
    status: false,
}


const ProfilReducers= (state = initialState, action) =>  {


    switch (action.type) {
     
        case PROFIL_SET:
          return {
            ...state,
            action
          };
        case ABOUTUS_SET:
          return {
            ...state,
            action
          };
        case SERVICES_SET:
          return {
            ...state,
            action
          };
        case CERTIFICATES_SET:
          return {
            ...state,
            action
          };
          case EDUCATION_SET:
            return {
              ...state,
              action
            };
        case PASSWORD_SET:
          return {
            ...state,
            action
          };
        default:
          return { ...state };
      }

}


export default ProfilReducers
