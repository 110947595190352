
import React, {  useState } from "react"
import { Col,
  Row
} from "reactstrap"
import Switch from "react-switch"
import { useDispatch } from "react-redux"
import { Services_GET } from "store/actions"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

const Healthcare = props => {
  
  const HealthcareData = props.info.services.Healthcare

  const [jobCategories, setjobCategories] = useState(HealthcareData.service)
  const [switchHealthcare, setswitchHealthcare] = useState(HealthcareData.status)

  const dispatch = useDispatch()

  const ChangeChexbox = e => {
    const { name, checked } = e.target

    const data = jobCategories.map(obj => {
      return obj.name == name ? { ...obj, checked } : obj
    })

    setjobCategories(data)
    dispatch(Services_GET(0,data,"Healthcare"))
  }

  const ServiceActive = e => {
    setswitchHealthcare(e)
    dispatch(Services_GET(1,e,"Healthcare"))
  }

  return (
    <Row>
      <hr></hr>
      <Col lg="12 mb-3 p-0">
        <span className="text-danger">Note:</span> If you are a UK registered healtcare and have the documents to prove this, you can tick Yes (Activate Service). Once you activate the service, all of your skills below will be activated. You can remove some of these abilities if you wish.
      </Col>
      <Col sm="12 border">
        <Row>
          <Col sm="2 pt-1  bg-soft bg-info">
            <label className=" col-form-label example-text-input">
            Activate Service :{" "}
            </label>
          </Col>
          <Col sm="10 mt-2">
            <Switch
              name="box"
              uncheckedIcon={<Offsymbol />}
              checkedIcon={<OnSymbol />}
              className="me-1 mb-sm-8 mb-2 "
              onColor="#02a499"
              onChange={e => ServiceActive(e)}
              checked={switchHealthcare}
            />
          </Col>
        </Row>
      </Col>
      <Col sm="12 mt-4">
        <Row className="p-0 ">
          {(jobCategories || []).map((item, key) => (
            <Col className="p-1 " key={key} xl={3} md={6}>
              <li
                to="#!"
                className="px-2 py-4  rounded bg-light bg-opacity-50 d-block mb-2"
              >
                {item.name}
                <span className="  float-end bg-opacity-100">
                  <div className="form-check form-switch" dir="ltr">
                    {item.checked ? (
                      <input
                        name={item.name}
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizesm"
                        defaultChecked
                        onChange={e => ChangeChexbox(e)}
                      />
                    ) : (
                      <input
                        name={item.name}
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizesm"
                        onChange={e => ChangeChexbox(e)}
                      />
                    )}
                  </div>
                </span>
              </li>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

export default Healthcare
