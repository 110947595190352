import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, Col, Container, Row, Modal } from 'reactstrap';
import { useParams } from 'react-router-dom';
//import images 
import avatar from "../../../assets/images/users/avatar-6.jpg";
import Swal from 'sweetalert2'
import AboutUs from './AboutUs';
import Sidebar from './Sidebar';
import FriendsModal from '../../../components/Jobs/FriendsModal';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Dna } from 'react-loader-spinner'
import { Jobs_view_GET, Jobs_apply_GET, Jobs_bookmarks_GET } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode as jwtDecode } from 'jwt-decode';
import { ThreeDots } from 'react-loader-spinner';
import Confetti from "react-confetti";
const JobOverview = () => {
    document.title = "Candidate Overview | The Wawy - Partner Dashboard ";

    const { id } = useParams()
    const dispatch = useDispatch()
    const [Job, setJob] = useState(null)
    const [apply, setApply] = useState(false)
    const [api, setApi] = useState(false)
    // refresh tek amacı  apply işleminin gerçekleşmesininden sonra API tekrar atmasını sağlar. useEffect tettikler. Durum değişklikleri handleApply() fonksiyonun içinde yer alır.
    const [refresh, setRefresh] = useState(false)
    var partner = jwtDecode(localStorage.getItem("authUser"))
    var partner_id = partner.partner_id

    useEffect(() => {
        localStorage.setItem('jobId', id)
        dispatch(Jobs_view_GET(localStorage.getItem('jobId')));

    }, [id])


    useEffect(() => {
        dispatch(Jobs_view_GET(localStorage.getItem('jobId')));

    }, [refresh])


    const handleApply = (job, status) => {

        setApply(true)

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, apply it!'

        }).then((result) => {
            if (result.isConfirmed) {
                setConfetti(true)
                dispatch(Jobs_apply_GET(Job));
                setTimeout(() => {
                    //konfeti sonlandırıyoruz.
                    setConfetti(false)
                    if (refresh) { setRefresh(false) } else { setRefresh(true) }
                    // apply durumunu değiştirerek jobs'ları tekrar sunucudan çekiyoruz. useEffecti tetikliyor.
                    setApply(false)
             
                }, 5500); // 5.5 saniye bekleyerek setConfetti(true) çağrısını yapar

            } else { setApply(false) }
        })




    }




    // konkfeti efekti için gerekli state'ler
    const [confetti, setConfetti] = useState(false)
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);



    // konfetiye ait divdeki verileri almak için kullanıyoruz.
    const confetiRef = useRef(null);

    useEffect(() => {
        if (confetiRef.current) {
            setHeight(confetiRef.current.clientHeight);
            setWidth(confetiRef.current.clientWidth);

        }
    }, [apply]);


    // Arkadaşlar pencerinisi aktif eder.
    const [friend_job, setFriend_job] = useState()
    const [modal_center, setmodal_center] = useState(false);
    function tog_center(job) { setFriend_job(job); setmodal_center(!modal_center); }

    // CSS styles for the modal
    const modalStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const contentStyles = {
        maxWidth: '100%', // Adjust the maximum width as needed
        width: 'auto',
    };


    // Sagadan gelen props dataları çekiyoruz.
    const { jobsData, Bookmark, applyStatus } = useSelector((state) => ({
        jobsData: state.JobsVivew.action,
        Bookmark: state.JobsBookmark.action,
        applyStatus: state.JobsApply.action

    }))

    // handleBookmark fonksiyonu bookmark işlemlerini gerçekleştiren saga hooksunu tetikler.
    const handleBookmark = (job, status) => { dispatch(Jobs_bookmarks_GET(job, status)); }
    // bookmark aktif veya pasif özelligini gösteren butunları verir  "jobsBoo"  return içerisindeki array döngüsünden parametresi gelir.
    const bookmarkfunc = (jobsBoo) => {


        const bookmarkItem = jobsBoo.bookmark.find((item) => item.id === partner_id);

        if (bookmarkItem.status == true) {

       


            return (

                <button onClick={() => handleBookmark(jobsBoo, false)} className="btn btn-warning">
                    <i className="bx bx-bookmark-plus "></i>
                </button>

            );
        } else {




            return (

                <button onClick={() => handleBookmark(jobsBoo, true)} className="bx bx-bookmark align-baseline">
                    <i className="bx bx-bookmark-plus "></i>
                </button>

            );
        }



    }


    if (jobsData) { var jobs = jobsData.JobsData.jobs; }
    if (Bookmark) { var bookmark_status = Bookmark.BookmarkData.data.status; }
    if (applyStatus) { var apply_status = applyStatus.ApplyData.data.status; }


    useEffect(() => {
        dispatch(Jobs_view_GET(localStorage.getItem('jobId')));
        setJob(jobs)

    }, [jobsData,Bookmark])
   // jobsData
    return (
        <React.Fragment   >


            <Modal size='lg' modalClassName="mt-[10%]"
                isOpen={modal_center}
                toggle={() => {
                    tog_center(x);
                }}
                style={modalStyles}
            >
                <div className="modal-content" style={contentStyles}>
                    <div className="modal-header  ">
                        <h5 className="modal-title mt-0">Your Friends</h5>
                        <button
                            type="button"
                            onClick={() => {
                                setmodal_center(false);
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-bod bg-slate-100 ">
                        <FriendsModal job={friend_job} />
                    </div>
                </div>
            </Modal>



            <div className="page-content" >
                <Container fluid >

                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Job" breadcrumbItem="View" />
                    {Job ? <>
                        <Row className="mt-4">
                            <Col lg={12} >
                                <Card className="mt-n4 bg-info bg-soft"  >

                                    <CardBody >
                                        {confetti ? <Confetti numberOfPieces={100} width={width} height={height} /> : <></>}
                                        <div className="text-center mb-4" ref={confetiRef}>
                                            <img src={avatar} alt="" className="avatar-md rounded-circle mx-auto d-block" />
                                            <h5 className="mt-3 mb-1">{Job.client_name}</h5>

                                            <div className="mx-auto">
                                                <span className="badge me-1 text-bg-info">{Job.job_type}</span>

                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <ul className="list-unstyled hstack gap-3 mb-0 flex-grow-1">
                                                <li>
                                                    <i className="bx bx-map align-middle"></i> {Job.city} / {Job.county}
                                                </li>
                                                <li>
                                                    <i className="bx bx-money align-middle"></i> {Job.fee.fee}£ hour / {Job.fee.hour}
                                                </li>
                                                <li>
                                                    <i className="bx bx-time align-middle"></i> {Job.days} days working / {Job.fee.type}
                                                </li>
                                            </ul>
                                            <div className="hstack gap-2">





                                                {Job.applications.find((item) => item.id === partner_id) ? <button className="btn btn-secondary    mr-1   justify-center flex">Apply </button>
                                                    : (apply
                                                        ? <button className="btn btn-secondary  pl-2 pr-2  mr-1  justify-center flex">
                                                            <ThreeDots
                                                                height="16"
                                                                width="20"
                                                                radius="9"
                                                                color="#fff"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            /></button> :
                                                        <button onClick={() => { handleApply(Job, true) }} className="btn btn-success    mr-1   justify-center flex">Apply </button>
                                                    )}


                                                <button onClick={() => tog_center(Job)} className="btn btn-danger  mr-1 ">Friend Send <i className="mdi mdi-account-heart  "></i></button>

                                                {Job.bookmark.find((item) => item.id === partner_id) ? bookmarkfunc(Job) :
                                                    <button onClick={() => handleBookmark(Job, true)} className="btn btn-light  ">
                                                        <i className="bx bx-bookmark align-baseline"></i>
                                                    </button>
                                                }


                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Sidebar  info={Job} />


                            <Col lg={9}>
                                <Card>
                                    <CardBody>
                                        <h5 className="mb-3">{Job.job_title}</h5>
                                 

                                        <div dangerouslySetInnerHTML={{ __html: Job.job_about }}></div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                        :
                        <Row style={{ height: "100vh" }} >

                            <Row>
                                <Col lg="4"></Col>
                                <Col lg="4" className="text-center">
                                    <Dna
                                        visible={true}
                                        height="160"
                                        width="160"
                                        ariaLabel="dna-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="dna-wrapper"
                                    />
                                </Col>
                                <Col lg="4"></Col>


                            </Row> </Row>}

                </Container>
            </div>

        </React.Fragment>
    );
}

export default JobOverview;