import { 
    DASHBOARD_NEWJOBS_SET,
    JOBS_SET
        } from "./actionTypes"


const initialState = {
    error:"",
    status: false,
}


const JobsReducers = (state = initialState,action) => {

    switch (action.type) {
        case DASHBOARD_NEWJOBS_SET:
          return {
            ...state,
            action
          };
        case JOBS_SET:
          return {
            ...state,
            action
          };
  
     
        default:
          return { ...state };
    }


}

export default JobsReducers
        