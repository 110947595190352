import React, { useEffect, useState, useRef } from 'react';
import {
    Card, CardTitle, CardBody, Col, Row, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

//import images
import jobs from "../../assets/images/jobs.png";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import { useDispatch, useSelector } from 'react-redux';
import FriendsModal from 'components/Jobs/FriendsModal';
import { Jobs_bookmarks_GET } from 'store/jobs_bookmark/actions';
import { Jobs_apply_GET } from 'store/actions';
import { jwtDecode as jwtDecode } from 'jwt-decode';
import Confetti from "react-confetti";
import Swal from 'sweetalert2'
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { PROFIL_IMG } from "ApiConfig";
import catwaiting from "../../assets/lottie/cat7.json"; // Lottie JSON dosyasının yolu
import Lottie from "lottie-react";

const LastJobs = props => {

    const dispatch = useDispatch();
    const partner = jwtDecode(localStorage.getItem("authUser"))
    const [modal_center, setmodal_center] = useState(false);
    const [bookmark, setBookmark] = useState(false)
    const [friends, setFriends] = useState(false)
    const [id, setId] = useState(partner.partner_id)
    // apply butonunun gösterilmesi ile alakalı degeri degeri taşır.  "apply" aynı anda konfeti efekti için div genişlerini önceden atanmasını sağlar (useEffect ile)
    const [apply, setApply] = useState(false)

    // refresh tek amacı  apply işleminin gerçekleşmesininden sonra API tekrar atmasını sağlar. useEffect tettikler. Durum değişklikleri handleApply() fonksiyonun içinde yer alır.
    const [refresh, setRefresh] = useState(false)

    // handleBookmark fonksiyonu bookmark işlemlerini gerçekleştiren saga hooksunu tetikler.
    const handleBookmark = (job, status) => { dispatch(Jobs_bookmarks_GET(job, status)); }
    const handleFriends = (status) => { setFriends(status) }

    // konfeti efektinin görünmesi için kullanılır genel durum değişikligi handleApply() fonksiyonun içinde yer alır.
    const [confetti, setConfetti] = useState(false)

    const handleApply = (job,status, key) => {

        // apply state'nin durumu true dönüyor ve apple butonu loading efektli butona dönüşüyor. 
        setApply(true)
        // keyno state degerine bulundugu job sıra degerini veriyoruz ve o job ait alandaki <Confetti> konponentini aktif olmasını sağlıyor (keyno == key) o anda döngüdeki key eşit olacak ve görünecek.
        setKeyno(key)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, apply it!'

        }).then((result) => {
            if (result.isConfirmed) {
                /*   Swal.fire(
                      'Applied!',
                      'Your request has been applied.',
                      'success'
                    ) */
                dispatch(Jobs_apply_GET(job));
                
                // işlem yapılan job'daki konfeti efektini aktif hale getiriyoruz.
                setConfetti(true);
                
                // konfeti efektini durmasını saglayan ve sunucudan durumu degişen jobs'ları tekrar çekmeyi saglayan zamanlama fonksiyonu
                setTimeout(() => {
                    //konfeti sonlandırıyoruz.
                    setConfetti(false)
                    if (refresh) { setRefresh(false) } else { setRefresh(true) }
                    // apply durumunu değiştirerek jobs'ları tekrar sunucudan çekiyoruz. useEffecti tetikliyor.
                    setApply(false)
                }, 5500); // 5.5 saniye bekleyerek setConfetti(true) çağrısını yapar

            } else { setApply(false) }
        })




    }



    // konkfeti efekti için gerekli state'ler
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const [divId, setDivId] = useState(null);
    const [keyno, setKeyno] = useState(null);  // bulundugu konum hangi jobda ise efekt orada başlayacak.

    // konfetiye ait divdeki verileri almak için kullanıyoruz.
    const confetiRef = useRef(null);

    useEffect(() => {
        if (confetiRef.current) {
            setHeight(confetiRef.current.clientHeight);
            setWidth(confetiRef.current.clientWidth);
            setDivId(confetiRef.current.id);
        }
    }, [apply]);









  

    // sayfa ilk yüklemede jobs'ları çekiyor.
    useEffect(() => {dispatch({ type: "DASHBOARD_NEWJOBS_GET" })}, [])

    // Arkadaşlar pencerinisi aktif eder.
    const [friend_job, setFriend_job]  = useState()
    function tog_center(job) {setFriend_job(job);setmodal_center(!modal_center); }

    // Sagadan gelen props dataları çekiyoruz.
    const { jobsData, Bookmark, applyStatus } = useSelector((state) => ({
        jobsData: state.Jobs.action,
        Bookmark: state.JobsBookmark.action,
        applyStatus: state.JobsApply.action

    }))

   
    var jobs = [];

    if (jobsData) { var jobs = jobsData.JobsData.jobs; }
    if (Bookmark) { var bookmark_status = Bookmark.BookmarkData.data.status; }
    if (applyStatus) { var apply_status = applyStatus.ApplyData.data.status; }



    // bookmark ile ilgili veya apple ile ilgili işlem yapıldıgında silme ve ekleme gibi tekrardan jobs'ları sunucdan çekiyoruz.
    useEffect(() => {dispatch({ type: "DASHBOARD_NEWJOBS_GET" }) }, [Bookmark, refresh]) 



    // bookmark aktif veya pasif özelligini gösteren butunları verir  "jobsBoo"  return içerisindeki array döngüsünden parametresi gelir.
    const bookmarkfunc = (jobsBoo) => {


        const bookmarkItem = jobsBoo.bookmark.find((item) => item.id === id);

        if (bookmarkItem.status == true) {
            return (
                <div className="mt-4" >
                    <button onClick={() => handleBookmark(jobsBoo, false)} className="btn btn-warning btn-sm pl-2 pr-2">
                        <i className="bx bx-bookmark-plus text-xs"></i>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="mt-4" >
                    <button onClick={() => handleBookmark(jobsBoo, true)} className="btn btn-secondary btn-sm pl-2 pr-2">
                        <i className="bx bx-bookmark-plus text-xs"></i>
                    </button>
                </div>
            );
        }



    }

// CSS styles for the modal
const modalStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  
  const contentStyles = {
    maxWidth: '100%', // Adjust the maximum width as needed
    width: 'auto',
  };

    return (
        <React.Fragment>
            <Col lg={12}>

                <Card>
                    <CardBody>
                        <h4 className="card-title mb-3">New Jobs</h4>





                        <Modal size='lg' modalClassName="mt-[10%]" 
                            isOpen={modal_center}
                            toggle={() => {
                                tog_center(x);
                            }}
                            style={modalStyles}
                        >
                              <div className="modal-content" style={contentStyles}>
                            <div className="modal-header  ">
                                <h5 className="modal-title mt-0">Your Friends</h5>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setmodal_center(false);
                                    }}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-bod bg-slate-100 ">
                                <FriendsModal job={friend_job} />
                            </div>
                            </div>
                        </Modal>


                        <div className="carousel-item active" data-bs-interval="3000">
                            {
                            // jobs döngümüz başladı.
                            }

                            {jobs.length !== 0 ? 
                            jobs.map((job, key) => (
                                                            
                                // id ve ref konfetimiz için kullanıyoruz.
                                <div key={key} className='relative' id={key} ref={confetiRef} >

                                    {
                                    // confetti true ise ve keyimiz keyno eşit ise keyno  handleApply(job, true, key)   buradan gelir apply butonuna tıkladıgımzda dogal olarak döngüdeki key ile apple butonu aynı keydir.
                                    } 
                                    {confetti ? (keyno == key ? <div className="confettie-wrap w-full"  > <Confetti numberOfPieces={40} width={width} height={height} /> </div> : console.log("Verem Oldum")) : <></>}
                                    <div className="bg-light p-3 d-flex mb-3 rounded">
                                      
                                        <img src={`${PROFIL_IMG}${job?.client_image}`} alt="" className="avatar-sm rounded me-3" />
                                        <div className="flex-grow-1">
                                            <h5 className="font-size-15 mb-2"><a href="candidate-overview" className="text-body">{job.client_name}</a> <span className="badge badge-soft-success">{job.job_type}</span></h5>
                                            <ul className="list-unstyled hstack gap-3 mb-0 flex-grow-1">
                                                <li>
                                                    <i className="bx bx-map align-middle"></i> {job.city} / {job.county}
                                                </li>
                                                <li>
                                                    <i className="bx bx-money align-middle"></i> {job.fee.fee}£ hour / {job.fee.hour}
                                                </li>
                                                <li>
                                                    <i className="bx bx-time align-middle"></i> {job.days} days working   / {job.fee.type}
                                                </li>
                                            </ul>
                                            <div>

                                            </div>
                                            <h5 className="font-size-15 mt-8"><a href="candidate-overview" className="text-body">{job.job_title}</a> </h5>

                                            <div className="bg-light my-4 d-flex mb-3 rounded ">
                                            <div dangerouslySetInnerHTML={{ __html: job.job_about }}></div>
                                               
                                            </div>
                                            <Row>
                                                <Col sm="4"></Col>
                                                <Col sm="3"></Col>
                                                <Col sm="5" className='flex justify-end'>

                                                    <div className="mt-4"><Link to={`/JobView/${job._id}`} className="btn btn-primary btn-sm mr-1 text-xs">View Details</Link></div>

                                                   
                                                        {
                                                         // daha önce bu job başvuru yapılmış mı diye kontrol ediyoruz. Eğer id'si varsa job içerisindeki applications içerisinde buton renginmiz tıklanmış hali alıcaktır ilk koşul çalışır
                                                            job.applications.find((item) => item.id === id)
                                                            ?
                                                            <div className="mt-4"><button className="btn btn-secondary btn-sm pl-2 pr-2  mr-1 text-xs justify-center flex"> <i className="bx bx-badge-check  text-xs"> </i>Apply</button></div>
                                                            :
                                                            // apply false ise ve daha önce bu job başvurulmamışsa görünecek buton
                                                            (!apply ? <div className="mt-4"><button onClick={() => { handleApply(job, true, key) }} className="btn btn-success btn-sm pl-2 pr-2  mr-1 text-xs  justify-center flex">Apply</button> </div> : <></>)
                                                      }



                                                    { apply ? 
                                                             (
                                                              keyno === key ?
                                                                            <div className="mt-4">
                                                                            <button className="btn btn-secondary btn-sm pl-2 pr-2  mr-1 text-xs  justify-center flex">
                                                                            <ThreeDots
                                                                                height="16"
                                                                                width="20"
                                                                                radius="9"
                                                                                color="#fff"
                                                                                ariaLabel="three-dots-loading"
                                                                                wrapperStyle={{}}
                                                                                wrapperClassName=""
                                                                                visible={true}
                                                                            /></button></div> 
                                                                            : (!job.applications.find((item) => item.id === id) ? <div className="mt-4"><button onClick={() => { handleApply(job, true, key) }} className="btn btn-success btn-sm pl-2 pr-2  mr-1 text-xs  justify-center flex">Apply</button> </div> : <></>)
                                                            )
                                                          : 
                                                          <></>}



                                                     <div className="mt-4"><button onClick={() =>  tog_center(job)} className="btn btn-danger btn-sm pl-2 pr-2  mr-1 "><i className="mdi mdi-account-heart   text-xs"></i></button></div>



                                                    {job.bookmark.find((item) => item.id === id) ? bookmarkfunc(job) : <div className="mt-4" key={key}>
                                                        <button onClick={() => handleBookmark(job, true)} className="btn btn-secondary btn-sm pl-2 pr-2">
                                                            <i className="bx bx-bookmark-plus text-xs"></i>
                                                        </button>
                                                    </div>}









                                                </Col>
                                            </Row>
                                        </div>



                                    </div>

                                </div>

                            ))


                             : 


                        
                               
                                     <Row> 
                                     <Col className="flex flex-col items-center justify-start " >
                                     <div className="alert alert-warning fade show" role="alert">We haven't found an offer advert for you yet. Please visit us again.</div>
                                    
                                     <Col xl={6} sm={12}>
                                         <Lottie
                                             animationData={catwaiting}
                                             loop
                                             autoplay={true}
                                             renderer="svg" // İsteğe bağlı olarak svg veya canvas olarak belirleyebilirsiniz
                                            
                                         />
                                     </Col>
                                     </Col>
                                     </Row>
                     

                                                             }

                        </div>






                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}


export default LastJobs;



/*

/** 
* Paste one or more documents here
*/

/*
{
    "client_id":1,
    "client_name":"Stephen Hadley",
    "job_type":"Nanies",
    "days":[{"day":"Monday","time":[{"start":"10:00PM","end":"12:00PM"},{"start":"14:00PM","end":"16:00PM"}] },{"day":"Tuesday","time":[{"start":"18:00AM","end":"20:00AM"},{"start":"20:00AM","end":"07:00PM"}] }],
    "job_title":"Postoperative Wound Care",
    "job_about":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    "fee":{"fee":"20","type":"hour"},
    "create_time":new Date(),
    "job_status":0,
    "requirements":["Engilish","Turkish","Spanish"],
    "premium":false,
    "case":"Emergency",
    "city":"London",
    "county":"Islington",
    "postcode":"N11EG",
    "views":[{"partner_id":"64677d1aff","partner_picture":"tosun.jpg","partner_name":"Ugur Tosun","views_time":"12.05.2023 12:00PM"},{"partner_id":"64677d1aff","partner_name":"Sinem Tosun","partner_picture":"sinem.jpg","views_time":"06.06.2023 20:35PM"}]
    
    
    }


*/