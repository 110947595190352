import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";


//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//jobs
import Jobs from "./jobs/reducer";

import JobsVivew from "./jobs_view/reducer"

import JobsBookmark from "./jobs_bookmark/reducer";

import JobsApply from "./jobs_apply/reducer";

import {JobsFriendReducers,JobsFriendCheckdReducers} from "./jobs_friend/reducer";

//projects
import projects from "./projects/reducer";

//team
import team from "./team/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Profil 
import Profil from "./Profil/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//Dasboard crypto
import DashboardCrypto from "./dashboard-crypto/reducer";

//Dasboard blog
import DashboardBlog from "./dashboard-blog/reducer";

//Dasboard job
import DashboardJob from "./dashboard-jobs/reducer";

//Schedule
import Schedule from "./schedule/reducer";

//Notify
import NotifyReducer from "./notification/reducer";


// inform
import InformReducer from "./inform/reducer";

//CheckNotifyReducer
import CheckNotifyReducer from "./LoginCheck/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  Jobs,
  JobsVivew,
  team,
  JobsBookmark,
  JobsApply,
  JobsFriendReducers,
  JobsFriendCheckdReducers,
  projects,
  tasks,
  contacts,
  Dashboard,
  Profil,
  DashboardSaas,
  DashboardCrypto,
  DashboardBlog,
  DashboardJob,
  Schedule,
  NotifyReducer,
  InformReducer,
  CheckNotifyReducer,
});

export default rootReducer;
