import React from "react"
import { Navigate } from "react-router-dom"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import RegisterVerification from "../pages/Authentication/RegisterVerification"

// Dashboard
import Dashboard from "../pages/Dashboard/index"


//Profil Pages
import Profile from "../pages/Profil/index" 
import ProfileEdit from "../pages/Profil/ProfileEdit"


//Jobs Pages
import JobOffers from "pages/Jobs"
import JobView from "pages/Jobs/JobOverview"
import AppliedOffers from "pages/Jobs/applied_offers"


//Chat Pages
import Chat from "pages/Chat"

//Commnets Pages
import Comments from "pages/Comments"

//Firends Pages
import Friends from "pages/Friends"

//Teams Pages
import Teams from "pages/Teams"

//Message Pages
import Messages from "pages/Message"

//Notification
import Notification from "pages/Notification"

const authProtectedRoutes = [
    
    //Dashboard
    { path: "/Dashboard", component: <Dashboard/> },

    //Jobs
    { path: "/JobOffers", component: <JobOffers />  },
    { path: "/AppliedOffers", component: <AppliedOffers />  },
    { path: "/JobView/:id", component: <JobView />  },

    //Messages
    { path: "/Messages", component: <Messages />  },

    //Notification
    { path: "/Notification", component: <Notification />  },

    //Friends
    { path: "/Friends", component: <Friends />  },

    //Friends
    { path: "/Comments", component: <Comments />  },

    //Chat
    { path: "/Chat", component: <Chat />  },
    //Chat
    { path: "/Teams", component: <Teams />  },
    
    //Profile
    { path: "/Profile", component: <Profile />  },
    { path: "/ProfileEdit", component: <ProfileEdit /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
   {
    path: "/",
    exact: true,
    component: < Navigate to="/Dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/RegisterVerification", component: <RegisterVerification />   }
]

export { authProtectedRoutes, publicRoutes }

