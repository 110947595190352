
import React, { useEffect, useState } from "react";
import { Row, Col, Input, TabPane, Form, FormGroup, Label, CardTitle, Card, CardBody, } from "reactstrap";

// Redux

import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";
import CertificatesCard from "../CertificatesCard";
import { jwtDecode as jwtDecode } from 'jwt-decode';

import uuid from 'react-uuid';
import { useDispatch } from "react-redux";



// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import * as FileSettings from 'filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename'
// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileRename);
FileSettings.registerPlugin(FilePondPluginFileRename)

const Tab_Certificates = (props) => {

  const [filesName, setfilesName] = useState('')
  const [files, setfiles] = useState()
  const [newfiles, setnewfiles] = useState()

  const dispatch = useDispatch();

 

  const Reset = () => {
    setfilesName('')
    setnewfiles(null)
    setfiles(null)
  
  }

  useEffect(() => {
    dispatch({ type: 'API_DASHBOARD_GET' })
   },[files===null])
 


  const handleInit = () => {
    FileSettings.registerPlugin(FilePondPluginFileRename);

    const partnerData = jwtDecode(localStorage.getItem("authUser"))
    const id = partnerData.partner_id
    const partner_name = partnerData.partner_name
    const code = partnerData.partner_code
    var tarih = new Date();
    var ay = tarih.getMonth() + 1; // JavaScript ay indeksi 0'dan başlar
    var yil = tarih.getFullYear();
    var date = ay + "-" + yil;

 



    if (files==[]) { console.log("Bosdur") } else {
     
      const token = localStorage.getItem('token')
      var fileid = uuid()
      FileSettings.setOptions({
        instantUpload: true,
        server: {
          url: 'https://partner.thewawy.co.uk:3027',
          process: {
            url: '/FilesUpload',
            method: 'POST',
            headers: {
              'x-customheader': 'Hello World',
              authorization: token,
              myfilename: filesName,
              fileid: fileid,
               partner_Id:id
            },
          },
          revert: {
            url: '/FilesDelete',
            method: 'DELETE',
            headers: {
              'x-customheader': 'Hello World',
              authorization:token,
              myfilename:filesName,
              fileid:fileid,
              id:id
              //  name : partner_name+'_'+code+'_'+id+'_'+date+'_'+'thewawy'+'_'+file.extension
            },
          }
        },
        onheaders: (res) => {
          res.setHeader('content-type', 'multipart/form-data'); // Yanıt başlıklarına 'content-type' eklendi.
        },
        onprocessfilerevert: (file) => {

          setfilesName('')
          setnewfiles(null)
          setfiles(null)
          dispatch({ type: 'API_DASHBOARD_GET' })
          // Revert işlemi başarılı bir şekilde tamamlandığında yapılacak işlemler burada yer alır
          console.log('Revert işlemi tamamlandı:');


        },
        onprocessfile: (error, file) => {
          // Dosya Yüklendiğinde Bilginlendirme Metni ve Yeni Dosya Ekleme Butonu Aktif olsun diye "newfiles" durumu 1 yapıyoruz. ve return içerisindeki if blogumuz aktif oluyor.
          setnewfiles(1)
      
          if (!error) {
            // yükleme başarılı oldu, bildirim göster
            // console.log(JSON.parse(file.serverId))
           
             // Yükleme işlemi bittikten sonra apimize bağlanıp var olan datamızı güncelliyoruz. CertificatesCard dosyamızdaki useEffect props güncellendigi anda yeni sertifikaları gösteriyor.
            dispatch({ type: 'API_DASHBOARD_GET' })
           


          } else {
            // hata oluştu, bildirim göster
            alert(`${file.filename} yüklenirken bir hata oluştu: ${error}`);
          }
        },
        fileRenameFunction: (file) => {
          const name = partner_name + '_' + code + '_' + id + '_' + date + '_' + 'thewawy' + '_' + file.extension;
          return `${name}${file.extension}`;
        },
      });
    }
    setfiles([]);



  };

  const handleNameChange = e => {

    const value = e.target.value
    setfilesName(value);


  }




  
  return (
    <TabPane
      tabId="4"
      id="v-pills-payment"
      role="tabpanel"
      aria-labelledby="v-pills-payment-tab"
    >
     
      <Row>
        <Col lg="12">
          <CardTitle className="h4 p-1">
            {" "}

            CERTIFICATES - ADD/REMOVE/SETTINGS
          </CardTitle>
        </Col>
        <Col lg="12">
          <Row>



         



            <Col lg={5} >
              <Card>
                <CardBody>
                  <div className="favorite-icon">
                    <Link to="#">
                      <i className="uil uil-heart-alt fs-18"></i>
                    </Link>
                  </div>
                  <div className="mt-4 mb-4">
                    <label className="form-label">File Name</label>
                    <input
                      name="filename"
                      value={filesName}
                      onChange={e =>  handleNameChange(e) }
                      onBlur={() =>  handleInit()  }
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="Exm : Driver Licance"
                    />
                  </div>

                  <div className="">


                    {filesName ?
                      <>
                        <FilePond


                          files={files}
                          onupdatefiles={setfiles}
                          maxFiles={3}
                          name="files" /* sets the file input name, it's filepond by default */
                          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        />

                        {newfiles == 1 ? <>



                          <div className="">
                          <span className="mt-4  ">Your file has been uploaded successfully. Click the </span>
                           <i className="mdi mdi-close-circle-outline  bg-soft text-danger rounded-circle font-size-18 mt-1 w "></i>
                            <span> icon to cancel the uploaded file. You can click the button below to add another new file.</span>
                          </div>

                          
                      
                          <div className="mt-4 hstack gap-2">

                            <button


                              onClick={() => Reset()}
                              className="btn btn-soft-danger w-100"
                            >
                              Add Another File
                            </button>
                          </div></> : <></>}

                      </>
                      : <>
                        <div className="text-center  bg-soft text-danger font-size-18 w-{20}px"><i className="bx bx-error-alt text-center"></i><p>The file upload window will appear after you provide the file name. Please enter the file name initially.</p></div>





                      </>}

                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col lg="12">
          <CertificatesCard />
        </Col>

      </Row>
    </TabPane>
  );
}


export default Tab_Certificates



/*

   <div className="mt-4 hstack gap-2">

      <button
       
      
        onClick={()=> handleInit()}
        className="btn btn-soft-primary w-100"
      >
        Save
      </button>
    </div>

*/