import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class TopBarEdit extends Component {
  render() {
    return (
        <div className="d-flex flex-wrap gap-2 mb-3 justify-content-end">
        

        <Link to="/Profile"
          type="button"
          className="btn btn-soft-primary"
        >
          <i className="bx bx-street-view font-size-16 align-middle me-2"></i>{" "}
          Profile View
        </Link>



 
      </div>
    )
  }
}
