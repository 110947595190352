// Redux
import { connect } from "react-redux";

import React, { useEffect, useState } from 'react';
import {  Input, TabPane, Form,  Label, FormFeedback  } from "reactstrap";

import { withTranslation } from "react-i18next";
import {  useDispatch ,useSelector} from "react-redux";
import { Link } from "react-router-dom";
import {  Education_GET } from 'store/Profil/actions';

import 'react-phone-input-2/lib/style.css';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';









const validationSchema = Yup.object().shape({
    HighSchoolName: Yup.string().required("it is required"),
    StartTime: Yup.string().required("it is required"),
    FisihTime: Yup.string().required("it is required"),
    University: Yup.string().required("it is required"),
    UniStartTime: Yup.string().required("it is required"),
    UniFisihTime: Yup.string().required("it is required"),
    UniDepartment: Yup.string().required("it is required"),
 
});

const Tab_5 = (props) => {

  const dispatch = useDispatch();
  const profilData = props.info;

 


  const [showToastProfil, setShowToastprofil] = useState(false);



  useEffect(() => {
    formik.setValues({
      ...formik.values,
    });
  }, []);

  const formik = useFormik({
    initialValues: {
        HighSchoolName: profilData?.education?.highscholl?.name,
        StartTime: profilData?.education?.highscholl?.start,
        FisihTime: profilData?.education?.highscholl?.finish,
        University: profilData?.education?.university?.university,
        UniStartTime: profilData?.education?.university?.start,
        UniFisihTime: profilData?.education?.university?.finish,
        UniDepartment: profilData?.education?.university?.name,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        
      // Form gönderildiğinde yapılacak işlemler
      dispatch(Education_GET(values));
      setShowToastprofil(true)

    },
  });


  // Saga'nın props güncellemesinde sonra gelen status durumuna göre uyarı veriyoruz.
  // Not: props.data.data ilk başta undfined döndügü için useEffect içerisinde kullandık. return içerisinde "undfined" degeri döndügü için kullanamadık.
  useEffect(() => {

    // props.data  useEffet de her zaman kontrol edildigi için "props.data" artık güncel oldugu için her sayfa yüklenişinde tekrar çalışıyor ve uyarı veriyor.
    // Bu sorunu düzeltmek için "showToast" adında boolen deerinde bir değişken tanımladık ve işlemlere göre false, true olarak düzenledik.
    if (showToastProfil) {

        const { status } = props.data.data

      if (status) {  toast.success("Profile Education Updated!"); } else { toast.warning("Profile Education Not Updated!"); }

    } else {
      console.log("Props data not work : Profile Saga tetiklendi ama props'a veri düşmedi. Yüksek ihtimal sunucu bağlantı sorunu yaşandı.")
      setShowToastprofil(false)
    }
    setShowToastprofil(false); 
  }, [props.data]);






 






  return (
    <TabPane tabId="5">



      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover

        theme="colored"

      />


      <div>
        <h4 className="card-title">Education Information</h4>
<br></br>
        <Form
          className="form-horizontal"
          onSubmit={formik.handleSubmit}
        >

 
<div className="d-flex">
<div className="mb-3 w-80 mr-4">
            <Label className="form-label">High School Name</Label>
            <Input
              id="HighSchoolName"
              name="HighSchoolName"
              className="form-control"
              placeholder="Enter High School Name"
              type="HighSchoolName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.HighSchoolName || ""}
              invalid={formik.touched.HighSchoolName && formik.errors.HighSchoolName}
            />
            {formik.touched.HighSchoolName && formik.errors.HighSchoolName && (
              <FormFeedback type="invalid">{formik.errors.HighSchoolName}</FormFeedback>
            )}
          </div>


          <div className="mb-3  mr-4">
            <Label className="form-label">Start Time</Label>
            <Input
              id="StartTime"
              name="StartTime"
              className="form-control"
              placeholder="Example: 2005"
              type="StartTime"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.StartTime || ""}
              invalid={formik.touched.StartTime && formik.errors.StartTime}
            />
            {formik.touched.StartTime && formik.errors.StartTime && (
              <FormFeedback type="invalid">{formik.errors.StartTime}</FormFeedback>
            )}
          </div>

          <div className="mb-3">
            <Label className="form-label">Fisih Time</Label>
            <Input
              id="FisihTime"
              name="FisihTime"
              className="form-control"
              placeholder="Example: 2010"
              type="FisihTime"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.FisihTime || ""}
              invalid={formik.touched.FisihTime && formik.errors.FisihTime}
            />
            {formik.touched.FisihTime && formik.errors.FisihTime && (
              <FormFeedback type="invalid">{formik.errors.FisihTime}</FormFeedback>
            )}
          </div>
</div>


<div className="d-flex">
<div className="mb-3 w-80 mr-4">
            <Label className="form-label">University Name</Label>
            <Input
              id="University"
              name="University"
              className="form-control"
              placeholder="University Name"
              type="University"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.University || ""}
              invalid={formik.touched.University && formik.errors.University}
            />
            {formik.touched.University && formik.errors.University && (
              <FormFeedback type="invalid">{formik.errors.University}</FormFeedback>
            )}
          </div>


          <div className="mb-3  mr-4">
            <Label className="form-label">Start Time</Label>
            <Input
              id="UniStartTime"
              name="UniStartTime"
              className="form-control"
              placeholder="Example: 2005"
              type="UniStartTime"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.UniStartTime || ""}
              invalid={formik.touched.UniStartTime && formik.errors.UniStartTime}
            />
            {formik.touched.UniStartTime && formik.errors.UniStartTime && (
              <FormFeedback type="invalid">{formik.errors.UniStartTime}</FormFeedback>
            )}
          </div>

          <div className="mb-3">
            <Label className="form-label">Fisih Time</Label>
            <Input
              id="UniFisihTime"
              name="UniFisihTime"
              className="form-control"
              placeholder="Example: 2010"
              type="UniFisihTime"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.UniFisihTime || ""}
              invalid={formik.touched.UniFisihTime && formik.errors.UniFisihTime}
            />
            {formik.touched.UniFisihTime && formik.errors.UniFisihTime && (
              <FormFeedback type="invalid">{formik.errors.UniFisihTime}</FormFeedback>
            )}
          </div>


</div>


<div className="mb-3 w-[70%] mr-4">
            <Label className="form-label">University Department</Label>
            <Input
              id="UniDepartment"
              name="UniDepartment"
              className="form-control"
              placeholder="Example: Computer Engineering"
              type="UniDepartment"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.UniDepartment || ""}
              invalid={formik.touched.UniDepartment && formik.errors.UniDepartment}
            />
            {formik.touched.UniDepartment && formik.errors.UniDepartment && (
              <FormFeedback type="invalid">{formik.errors.UniDepartment}</FormFeedback>
            )}
          </div>









          {/* Diğer form alanları */}

          <div className="tw:none mt-4 d-grid">
            <button className=" btn btn-primary btn-block" type="submit">
              Update
            </button>
          </div>

    
        </Form>
      </div>
    </TabPane>
  );
};





const mapStateToProps = (state) => {
    return {
      data: state.Profil.action,
  
    };
  };
  
  
  export default connect(mapStateToProps)((withTranslation())(Tab_5))