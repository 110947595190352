import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import {  map } from "lodash";

import commnetimg from "../../assets/images/my/comment.png"






class Comments extends Component {
  constructor(props) {
    super(props);
    this.state =    { };
  }



 




  render() {
    let comments = this.props.comments? this.props.comments : [];
    return (
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Last Comments</CardTitle>
              {
                comments.length !== 0  ? 
                map(comments, (comment, index) => (
                  <div className="d-flex mb-4" key={index}>
                    <div className="me-3">
                      
                        <img
                          className="media-object rounded-circle avatar-xs"
                          alt=""
                          src={commnetimg}
                        />
                      
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-size-13 mb-1">{comment.client_name}</h5>
                      <p className="text-muted mb-1">{comment.comment}</p>
              
                    </div>
           
                  </div>
                ))
                 :  <>No any comment...</>
              }
          <div className="text-center mt-4 pt-2">
            <Link to="/Comments" className="btn btn-primary btn-sm">
              View more
            </Link>
          </div>
        </CardBody>
      </Card>
    );
  }
}

Comments.propTypes = {
  comments: PropTypes.array,
};

export default Comments;
