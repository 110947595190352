import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col} from 'reactstrap';

//import images
import jobs from "../../assets/images/jobs.png";

import toast, { Toaster } from 'react-hot-toast'
;
import "../../../node_modules/swiper/swiper.scss";
// Import Swiper styles
import 'swiper/css';
const InviteFriends  = () => {
    const [toastStatus, setToastStatus] = useState(true)
    const copyToClipboard = async text => {
        setToastStatus(true);
        try {
          await navigator.clipboard.writeText(text);
          

          toast.success(
            <>
              <div>
                <strong>{"Link Copied"}</strong> <br />{"Thanks, you copied the link to The wavy site. You can share it with your friends."}
              </div>
            </>
          );


          setTimeout(() => {
            setToastStatus(false);
          }, 4000); // 2 saniye sonra setToastStatus(false) çalışır
      
        



        } catch (err) {
          
        }
      };



        return (
            <React.Fragment>
                {toastStatus ? <Toaster position="top-center" reverseOrder={false} /> : <></>}
                <Col lg={12}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <div>
                                <h4 className="card-title mb-3">Invite your friends to WAWY</h4>
                                <p className="text-muted">Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally.</p>
                                <div>
                                    <Link onClick={() => copyToClipboard("https://thewawy.co.uk/")}  className="btn btn-primary btn-sm"><i className='bx bx-user-plus align-middle'></i> Invite friends click copy</Link>
                                </div>
                            </div>
                            <div>
                                <img src={jobs} alt="" height="130" />
                            </div>
                        </div>
                    </CardBody>
                </Card>
      
            </Col>
            </React.Fragment>
        );

    
}

export default InviteFriends;