import { takeEvery, put, call } from "redux-saga/effects"

import axios from "axios"

import { API_SERVICE_QUERY } from "ApiConfig"

// Login Redux States
import { API_DASHBOARD_GET } from "./actionTypes"
import { Dashboard_SET } from "./actions"
import { jwtDecode as jwtDecode } from 'jwt-decode';

function* Dashboard() {
  async function API() {
    var partner = jwtDecode(localStorage.getItem("authUser"))
    var id = partner.partner_id

    const response = await axios
      .post(`${API_SERVICE_QUERY}Dashboard`, {
        partner_id: id,
        token: JSON.parse(localStorage.getItem("authUser")),
      })
      .then(response => response)
      .catch(err => {
        console.log("Bitmedi")
        // LoginButtonStatus(dispatch,"a")
      })

    localStorage.setItem("DataDashboard", JSON.stringify(response.data))
    return response.data.ServerDashboard
  }

  // Login İşleminde Sunucudan Dönen Yanıt
  const datam = yield call(API)

  yield put(Dashboard_SET(datam))
}

function* MyDashboard() {
  yield takeEvery(API_DASHBOARD_GET, Dashboard)
}

export default MyDashboard
