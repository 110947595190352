import { takeEvery, put, call } from "redux-saga/effects"
import axios from "axios";
import { API_SERVICE_UPDATE } from "ApiConfig";
import { jwtDecode as jwtDecode } from 'jwt-decode';
import {
   JOBS_APPLY_GET,
  
        } from "./actionTypes"

import { Jobs_apply_SET } from "./actions";







function* ApplyJobs ({ApplyData}) {



   async function API() {
       var partner = jwtDecode(localStorage.getItem("authUser"))
       var id = partner.partner_id
      
 
       const response = await axios
          .post(`${API_SERVICE_UPDATE}JobsApply`, {
            token: JSON.parse(localStorage.getItem("authUser")), 
            partner_id: id,
            partner:partner,
            job:ApplyData
          })
          .then(response => response)
          .catch(err => {
             console.log("Bitmedi")
             // LoginButtonStatus(dispatch,"a")
          })
 
 
       return response
    }
 
    // Login İşleminde Sunucudan Dönen Yanıt
    const datam = yield call(API)


    yield put(Jobs_apply_SET(datam)) 

}



function* JobsApply() {


    yield takeEvery(JOBS_APPLY_GET, ApplyJobs)
 }
 
 export default JobsApply