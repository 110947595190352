// Redux
import { connect } from "react-redux";

import React, { useEffect, useState, useRef  } from 'react';
import { Col, Input, TabPane, Form, FormGroup, Label, CardTitle, CardBody, Card, Alert, Container, Row, FormFeedback,Button  } from "reactstrap";
import Select from "react-select";
import Switch from "react-switch";
import { withTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Profil_GET } from 'store/Profil/actions';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import { jwtDecode as jwtDecode } from 'jwt-decode';
import uuid from 'react-uuid';
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import * as FileSettings from 'filepond'
import FilePondPluginFileRename from 'filepond-plugin-file-rename'
// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import { PROFIL_IMG } from "ApiConfig";



// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileRename, FilePondPluginFileValidateType);
FileSettings.registerPlugin(FilePondPluginFileRename)



const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      Yes
    </div>
  );
};


const myStyles = {
  position: 'relative',
  bottom: '17px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: '2px 66px 10px 68px',
  color: 'white',
};


const validationSchema = Yup.object().shape({
  fullname: Yup.string().required("Full Name is required"),
  gender: Yup.string().required("Gender is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  address: Yup.string().required("Address is required"),
  phone: Yup.string().required("Phone is required"),
  city: Yup.string().required("City is required"),
  county: Yup.string().required("County is required"),
  postcode: Yup.string().required("Postcode is required"),
});

const Tab_1 = (props) => {


  const dispatch = useDispatch();
  const profilData = props.info;

 

  const [profilImg, setprofilImg] = useState(profilData.profilimage.picture_path);
  const [cigarette, setSwitchCigarette] = useState(profilData.cigarette);
  const [alcohol, setSwitchAlcohol] = useState(profilData.alcohol);
  const [showToastProfil, setShowToastprofil] = useState(false);
  const [image, setImage] = useState()

  const useAlcohol = (e) => {
    setSwitchAlcohol(e);
   
  };

  const useCigarette = (e) => {
    setSwitchCigarette(e);
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      cigarette: cigarette,
      alcohol: alcohol,
    });
  }, [cigarette, alcohol]);

  const formik = useFormik({
    initialValues: {
      fullname: profilData.fullName,
      gender: profilData.gender,
      email: profilData.email,
      address: profilData.address,
      phone: profilData.phone,
      city: profilData.city,
      county: profilData.county,
      postcode: profilData.postcode,
      cigarette: cigarette,
      alcohol: alcohol,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Form gönderildiğinde yapılacak işlemler
      dispatch(Profil_GET(values));
      setShowToastprofil(true)

    },
  });


  // Saga'nın props güncellemesinde sonra gelen status durumuna göre uyarı veriyoruz.
  // Not: props.data.data ilk başta undfined döndügü için useEffect içerisinde kullandık. return içerisinde "undfined" degeri döndügü için kullanamadık.
  useEffect(() => {

    // props.data  useEffet de her zaman kontrol edildigi için "props.data" artık güncel oldugu için her sayfa yüklenişinde tekrar çalışıyor ve uyarı veriyor.
    // Bu sorunu düzeltmek için "showToast" adında boolen deerinde bir değişken tanımladık ve işlemlere göre false, true olarak düzenledik.
    if (showToastProfil) {

      const { status } = props.data.data

      if (status) { setShowToastprofil(false); toast.success("Profile Updated!"); } else { toast.warning("Profile Not Updated!"); }

    } else {
      console.log("Props data not work : Profile Saga tetiklendi ama props'a veri düşmedi. Yüksek ihtimal sunucu bağlantı sorunu yaşandı.")
      setShowToastprofil(false)
    }

  }, [props.data]);





  const filePondRef = useRef(null);
  const handleInit = () => {


   

    const partnerData = jwtDecode(localStorage.getItem("authUser"))
    const id = partnerData.partner_id
    const partner_name = partnerData.partner_name
    const code = partnerData.partner_code
    var tarih = new Date();
    var ay = tarih.getMonth() + 1; // JavaScript ay indeksi 0'dan başlar
    var yil = tarih.getFullYear();
    var date = ay + "-" + yil;





    if (image == []) { console.log("Bosdur") } else {

      const token = localStorage.getItem('token')
      var fileid = uuid()
      FileSettings.setOptions({
        instantUpload: true,

        server: {
          url: 'https://partner.thewawy.co.uk:3028',
          process: {
            url: '/PicturesUpload',
            method: 'POST',
            headers: {
              'x-customheader': 'Hello World',
              authorization: token,
              fileid: fileid,
              partner_Id: id
            },
          },
          revert: {
            url: '/PicturesDelete',
            method: 'DELETE',
            headers: {
              'x-customheader': 'Hello World',
              authorization: token,
              fileid: fileid,
              id: id
              //  name : partner_name+'_'+code+'_'+id+'_'+date+'_'+'thewawy'+'_'+file.extension
            },
          }

        },
        onheaders: (res) => {
          res.setHeader('content-type', 'multipart/form-data'); // Yanıt başlıklarına 'content-type' eklendi.
        },
        onprocessfilerevert: (file) => {



          setImage(null)
          dispatch({ type: 'API_DASHBOARD_GET' })
          // Revert işlemi başarılı bir şekilde tamamlandığında yapılacak işlemler burada yer alır
          console.log('Revert işlemi tamamlandı:');


        },
        onprocessfile: (error, file) => {
          // Dosya Yüklendiğinde Bilginlendirme Metni ve Yeni Dosya Ekleme Butonu Aktif olsun diye "newfiles" durumu 1 yapıyoruz. ve return içerisindeki if blogumuz aktif oluyor.


          if (!error) {
            // yükleme başarılı oldu, bildirim göster
            // console.log(JSON.parse(file.serverId))

            // Yükleme işlemi bittikten sonra apimize bağlanıp var olan datamızı güncelliyoruz. CertificatesCard dosyamızdaki useEffect props güncellendigi anda yeni sertifikaları gösteriyor.
            dispatch({ type: 'API_DASHBOARD_GET' })



          } else {
            // hata oluştu, bildirim göster
            alert(`${file.filename} yüklenirken bir hata oluştu: ${error}`);
          }
        },
        fileRenameFunction: (file) => {
          const name = partner_name + '_' + code + '_' + id + '_' + date + '_' + 'thewawy' + '_' + file.extension;
          return `${name}`;
        },
      });
    }
    setImage([]);

    

  };
  
 

  const handleClick = () => {
    if (filePondRef.current) {
      filePondRef.current.browse();
    }
  };
/*
  const { data } = useSelector((state) => ({
    data: state.Profil.action,
  }));
*/


  return (
    <TabPane tabId="1">



      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover

        theme="colored"

      />


      <div>
        <div className="mb-1">
          <Row>
            <Col sm="6 mt-2">
              <CardTitle className="h4">Profile information</CardTitle>
              <p className="card-title-desc">Fill all information below</p>
            </Col>
            <Col sm="3 mt-2">
              <Label className="form-label">Do you use cigarettes ?</Label>
              <br />
              <Switch
                name="box"
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                className="me-1 mb-sm-4"
                onColor="#02a499"
                onChange={(e) => useCigarette(e)}
                checked={cigarette}
              />
            </Col>
            <Col sm="3 mt-2">
              <Label className="form-label">Do you use alcohol ?</Label>
              <br />
              <Switch
                name="box"
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                className="me-1 mb-sm-8"
                onColor="#02a499"
                onChange={(e) => useAlcohol(e)}
                checked={alcohol}
              />
            </Col>
          </Row>
        </div>

        <Form
          className="form-horizontal"
          onSubmit={formik.handleSubmit}
        >

          <div className="mb-3">
            <Row>

           
              <Col md="2 mt-2" xxl="2 mt-2" lg="2 mt-2" sm="2 mt-2" className="flex justify-center" >
               <div className=" w-40  ">
                <FilePond
                  allowMultiple={false} // Tek bir dosya yükleme
                  acceptedFileTypes={['image/*']} // Sadece resim dosyalarını kabul et
                  labelIdle={`<img src="${PROFIL_IMG}${profilImg}"  class="rounded-circle avatar-xl" style="width:140px; height:140px;"  />`}
                  

                  imagePreviewHeight={200} // Önizleme resminin yüksekliği <img src="${defaultImage}" style={{ width= "155px" }} /> <span className=" text-3xl mt-n10 relative bottom-17 bg-black bg-opacity-50 p-2 pl-68 pr-66 text-white w-155" style{{font-size= 31px;margin-top= -46px; height:55px}} >Change</span>
                  stylePanelLayout='circle' // Yuvarlak stil
                 
                  styleButtonProcessItemPosition="bottom-right" // İşlem düğmesini alt sağ köşeye yerleştirir
                  styleLoadIndicatorPosition="bottom-right" // Yükleme simgesini alt sağ köşeye yerleştirir
                  styleProgressIndicatorPosition="bottom-right"
                  image={image}
                  onupdatefiles={setImage}
                  ref={filePondRef} 
                  oninit={handleInit}
                  allowRevert={false}
                  styleButtonRemoveItemPosition="left-bottom" // İptal simgesini alt sol köşeye yerleştirir
            
                  



                  name="image" /* sets the file input name, it's filepond by default */
                />

<i  onClick={() => handleClick()}  className="mdi mdi-circle-edit-outline relative bottom-[34px] text-white bg-black opacity-75 rounded-full w-[34px] block text-center hover:cursor-pointer " style={{ fontSize: "22px", marginLeft:'40%' }}></i>
</div>
              </Col>
              <Col     md="10 mt-2" xxl="10 mt-2" lg="10 mt-2" sm="10 mt-2"></Col>
            </Row>

          </div>
          <div className="mb-3">
            <Label className="form-label">Full Name</Label>
            <Input
              id="fullname"
              name="fullname"
              className="form-control"
              placeholder="Enter fullname"
              type="fullname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullname || ""}
              invalid={formik.touched.fullname && formik.errors.fullname}
            />
            {formik.touched.fullname && formik.errors.fullname && (
              <FormFeedback type="invalid">{formik.errors.fullname}</FormFeedback>
            )}
          </div>

          <div className="mb-3">
            <Label className="form-label">Gender</Label>
            <Select
              name="gender"
              options={[
                { value: "Women", label: "Women" },
                { value: "Man", label: "Man" },
                { value: "Other", label: "Other" },
              ]}
              onChange={(selectedOption) => formik.setFieldValue("gender", selectedOption.value)}
              onBlur={formik.handleBlur}
              value={formik.values.gender ? { value: formik.values.gender, label: formik.values.gender } : null}
              invalid={formik.touched.gender && formik.errors.gender}
            />

            {formik.touched.gender && formik.errors.gender && (

              <FormFeedback type="invalid">{formik.errors.gender}</FormFeedback>
            )}
          </div>

          <div className="mb-3">
            <Label className="form-label">Phone</Label>
            <PhoneInput
              inputProps={{
                name: 'phone',


              }}

              country={'gb'}
              name='phone'
              onChange={(value, ...props) => {

                formik.setFieldValue("phone", value); // formik form alanına değer set etme
                // burada yapılacak işlemler, alınan değerin formatlanması vb. gibi işlemler yapılabilir.
              }}
              onBlur={formik.handleBlur}
              value={formik.values.phone || ""}
              invalid={
                formik.touched.phone && formik.errors.phone ? true : false
              }
              inputStyle={{
                height: "40px",
                width: "100%"
              }}
            />


            {formik.touched.phone && formik.errors.phone ? (
              <FormFeedback type="invalid">{formik.errors.phone}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Email</Label>
            <Input
              name="email"
              type="email"
              placeholder="Enter email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email || ""}
              invalid={
                formik.touched.email && formik.errors.email ? true : false
              }
            />
            {formik.touched.email && formik.errors.email ? (
              <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Full Address</Label>
            <Input
              name="address"
              type="textarea"
              placeholder="Enter address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address || ""}
              invalid={
                formik.touched.address && formik.errors.address ? true : false
              }
            />
            {formik.touched.password && formik.errors.address ? (
              <FormFeedback type="invalid">{formik.errors.address}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">City</Label>
            <Input
              name="city"
              type="text"
              placeholder="Enter city"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city || ""}
              invalid={
                formik.touched.city && formik.errors.city ? true : false
              }
            />
            {formik.touched.city && formik.errors.city ? (
              <FormFeedback type="invalid">{formik.errors.city}</FormFeedback>
            ) : null}
          </div>


          <div className="mb-3">
            <Label className="form-label">County (District)</Label>
            <Input
              name="county"
              type="text"
              placeholder="Enter county"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.county || ""}
              invalid={
                formik.touched.county && formik.errors.county ? true : false
              }
            />
            {formik.touched.county && formik.errors.county ? (
              <FormFeedback type="invalid">{formik.errors.county}</FormFeedback>
            ) : null}
          </div>


          <div className="mb-3">
            <Label className="form-label">Postcode</Label>
            <Input
              name="postcode"
              type="text"
              placeholder="Enter postcode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.postcode || ""}
              invalid={
                formik.touched.postcode && formik.errors.postcode ? true : false
              }
            />
            {formik.touched.postcode && formik.errors.postcode ? (
              <FormFeedback type="invalid">{formik.errors.postcode}</FormFeedback>
            ) : null}
          </div>

          {/* Diğer form alanları */}

          <div className="tw:none mt-4 d-grid">
            <button className=" btn btn-primary btn-block" type="submit">
              Update
            </button>
          </div>

  
        </Form>
      </div>
    </TabPane>
  );
};




const mapStateToProps = (state) => {
  return {
    data: state.Profil.action,

  };
};


export default connect(mapStateToProps)((withTranslation())(Tab_1))
