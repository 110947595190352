


  import React, { useEffect, useState, useRef } from 'react';
import {
    Card, CardTitle, CardBody, Form,Col, Row, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
    Modal,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormFeedback,Label
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
//import images
import Select from "react-select";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import { useDispatch, useSelector } from 'react-redux';
import FriendsModal from 'components/Jobs/FriendsModal';
import { Jobs_bookmarks_GET } from 'store/jobs_bookmark/actions';
import { Jobs_apply_GET } from 'store/actions';
import { jwtDecode as jwtDecode } from 'jwt-decode';
import Confetti from "react-confetti";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { ThreeDots } from 'react-loader-spinner';
import * as Yup from "yup";
import { useFormik } from "formik";
import { PROFIL_IMG } from "ApiConfig";
import catwaiting from "../../assets/lottie/cat7.json"; // Lottie JSON dosyasının yolu
import Lottie from "lottie-react";



const Jobs = props => {
  document.title = 'Jobs | The Wawy - Partner Dashboard ';
    const dispatch = useDispatch();
    const partner = jwtDecode(localStorage.getItem("authUser"))
    const [modal_center, setmodal_center] = useState(false);
    const [bookmark, setBookmark] = useState(false)
    const [friends, setFriends] = useState(false)
    const [id, setId] = useState(partner.partner_id)
    // apply butonunun gösterilmesi ile alakalı degeri degeri taşır.  "apply" aynı anda konfeti efekti için div genişlerini önceden atanmasını sağlar (useEffect ile)
    const [apply, setApply] = useState(false)

    // refresh tek amacı  apply işleminin gerçekleşmesininden sonra API tekrar atmasını sağlar. useEffect tettikler. Durum değişklikleri handleApply() fonksiyonun içinde yer alır.
    const [refresh, setRefresh] = useState(false)

    // handleBookmark fonksiyonu bookmark işlemlerini gerçekleştiren saga hooksunu tetikler.
    const handleBookmark = (job, status) => { dispatch(Jobs_bookmarks_GET(job, status)); }
    const handleFriends = (status) => { setFriends(status) }

    // konfeti efektinin görünmesi için kullanılır genel durum değişikligi handleApply() fonksiyonun içinde yer alır.
    const [confetti, setConfetti] = useState(false)

    const handleApply = (job,status, key) => {

        // apply state'nin durumu true dönüyor ve apple butonu loading efektli butona dönüşüyor. 
        setApply(true)
        // keyno state degerine bulundugu job sıra degerini veriyoruz ve o job ait alandaki <Confetti> konponentini aktif olmasını sağlıyor (keyno == key) o anda döngüdeki key eşit olacak ve görünecek.
        setKeyno(key)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, apply it!'

        }).then((result) => {
            if (result.isConfirmed) {
                /*   Swal.fire(
                      'Applied!',
                      'Your request has been applied.',
                      'success'
                    ) */
                dispatch(Jobs_apply_GET(job));
                
                // işlem yapılan job'daki konfeti efektini aktif hale getiriyoruz.
                setConfetti(true);
                
                // konfeti efektini durmasını saglayan ve sunucudan durumu degişen jobs'ları tekrar çekmeyi saglayan zamanlama fonksiyonu
                setTimeout(() => {
                    //konfeti sonlandırıyoruz.
                    setConfetti(false)
                    if (refresh) { setRefresh(false) } else { setRefresh(true) }
                    // apply durumunu değiştirerek jobs'ları tekrar sunucudan çekiyoruz. useEffecti tetikliyor.
                    setApply(false)
                }, 5500); // 5.5 saniye bekleyerek setConfetti(true) çağrısını yapar

            } else { setApply(false) }
        })




    }



    const [serviceCheck, setserviceCheck] = useState(null)

    const handleCheckService = (value) => {

setserviceCheck(value)
    }

    const formik = useFormik({
      initialValues: {
        city: null,
        county: null,
        patient: null,
        service:null,
   
      },
    
      onSubmit: (values) => {
        // Form gönderildiğinde yapılacak işlemler
       // dispatch(Profil_GET(values));
      //  setShowToastprofil(true)

      },
    });


    // konkfeti efekti için gerekli state'ler
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);
    const [divId, setDivId] = useState(null);
    const [keyno, setKeyno] = useState(null);  // bulundugu konum hangi jobda ise efekt orada başlayacak.

    // konfetiye ait divdeki verileri almak için kullanıyoruz.
    const confetiRef = useRef(null);

    useEffect(() => {
        if (confetiRef.current) {
            setHeight(confetiRef.current.clientHeight);
            setWidth(confetiRef.current.clientWidth);
            setDivId(confetiRef.current.id);
        }
    }, [apply]);









  

    // sayfa ilk yüklemede jobs'ları çekiyor.
    useEffect(() => {dispatch({ type: "DASHBOARD_NEWJOBS_GET" })}, [])

    // Arkadaşlar pencerinisi aktif eder.
    const [friend_job, setFriend_job]  = useState()
    function tog_center(job) {setFriend_job(job);setmodal_center(!modal_center); }

    // Sagadan gelen props dataları çekiyoruz.
    const { jobsData, Bookmark, applyStatus } = useSelector((state) => ({
        jobsData: state.Jobs.action,
        Bookmark: state.JobsBookmark.action,
        applyStatus: state.JobsApply.action

    }))

   
    var jobs = [];

    if (jobsData) { var jobs = jobsData.JobsData.jobs; }
    if (Bookmark) { var bookmark_status = Bookmark.BookmarkData.data.status; }
    if (applyStatus) { var apply_status = applyStatus.ApplyData.data.status; }



    // bookmark ile ilgili veya apple ile ilgili işlem yapıldıgında silme ve ekleme gibi tekrardan jobs'ları sunucdan çekiyoruz.
    useEffect(() => {dispatch({ type: "DASHBOARD_NEWJOBS_GET" }) }, [Bookmark, refresh]) 



    // bookmark aktif veya pasif özelligini gösteren butunları verir  "jobsBoo"  return içerisindeki array döngüsünden parametresi gelir.
    const bookmarkfunc = (jobsBoo) => {


        const bookmarkItem = jobsBoo.bookmark.find((item) => item.id === id);

        if (bookmarkItem.status == true) {
            return (
                <div className="mt-4" >
                    <button onClick={() => handleBookmark(jobsBoo, false)} className="btn btn-warning btn-sm pl-2 pr-2">
                        <i className="bx bx-bookmark-plus text-xs"></i>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="mt-4" >
                    <button onClick={() => handleBookmark(jobsBoo, true)} className="btn btn-secondary btn-sm pl-2 pr-2">
                        <i className="bx bx-bookmark-plus text-xs"></i>
                    </button>
                </div>
            );
        }



    }

// CSS styles for the modal
const modalStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  
  const contentStyles = {
    maxWidth: '100%', // Adjust the maximum width as needed
    width: 'auto',
  };






  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Breadcrumbs title="Page" breadcrumbItem="Jobs" />
         <div className=' '>
      
      <Row>
        <Col lg={3}>

        <Card className=''>
    <CardBody>
  

    <Form className="form-horizontal" onSubmit={formik.handleSubmit} >
       <div className="mb-3">
            <Label className="form-label">City</Label>
            <Select
              name="city"
              options={[
                { value: "Aberdeen", label: "Aberdeen" },
                { value: "Armagh", label: "Armagh" },
                { value: "Bangor", label: "Bangor" },
                { value: "Bath", label: "Bath" },
                { value: "Belfast", label: "Belfast" },
                { value: "Birmingham", label: "Birmingham" },
                { value: "Bradford", label: "Bradford" },
                { value: "Brighton", label: "Brighton" },
                { value: "Bristol", label: "Bristol" },
                { value: "Cambridge", label: "Cambridge" },
                { value: "Canterbury", label: "Canterbury" },
                { value: "Cardiff", label: "Cardiff" },
                { value: "Carlisle", label: "Carlisle" },
                { value: "Chelmsford", label: "Chelmsford" },
                { value: "Chester", label: "Chester" },
                { value: "Chichester", label: "Chichester" },
                { value: "Coventry", label: "Coventry" },
                { value: "Derby", label: "Derby" },
                { value: "Derry", label: "Derry" },
                { value: "Dundee", label: "Dundee" },
                { value: "Durham", label: "Durham" },
                { value: "Edinburgh", label: "Edinburgh" },
                { value: "Ely", label: "Ely" },
                { value: "Exeter", label: "Exeter" },
                { value: "Glasgow", label: "Glasgow" },
                { value: "Gloucester", label: "Gloucester" },
                { value: "Hereford", label: "Hereford" },
                { value: "Inverness", label: "Inverness" },
                { value: "Lancaster", label: "Lancaster" },
                { value: "Leeds", label: "Leeds" },
                { value: "Leicester", label: "Leicester" },
                { value: "Lichfield", label: "Lichfield" },
                { value: "Lincoln", label: "Lincoln" },
                { value: "Liverpool", label: "Liverpool" },
                { value: "London", label: "London" },
                { value: "Londonderry", label: "Londonderry" },
                { value: "Manchester", label: "Manchester" },
                { value: "Newcastle", label: "Newcastle" },
                { value: "Newport", label: "Newport" },
                { value: "Newry", label: "Newry" },
                { value: "Norwich", label: "Norwich" },
                { value: "Nottingham", label: "Nottingham" },
                { value: "Oxford", label: "Oxford" },
                { value: "Perth", label: "Perth" },
                { value: "Peterborough", label: "Peterborough" },
                { value: "Plymouth", label: "Plymouth" },
                { value: "Portsmouth", label: "Portsmouth" },
                { value: "Preston", label: "Preston" },
                { value: "Ripon", label: "Ripon" },
                { value: "St Albans", label: "St Albans" },
                { value: "St Asaph", label: "St Asaph" },
                { value: "St Davids", label: "St Davids" },
                { value: "Salford", label: "Salford" },
                { value: "Salisbury", label: "Salisbury" },
                { value: "Sheffield", label: "Sheffield" },
                { value: "Southampton", label: "Southampton" },
                { value: "Stirling", label: "Stirling" },
                { value: "Stoke-on-Trent", label: "Stoke-on-Trent" },
                { value: "Sunderland", label: "Sunderland" },
                { value: "Swansea", label: "Swansea" },
                { value: "Truro", label: "Truro" },
                { value: "Wakefield", label: "Wakefield" },
                { value: "Wells", label: "Wells" },
                { value: "Westminster", label: "Westminster" },
                { value: "Winchester", label: "Winchester" },
                { value: "Wolverhampton", label: "Wolverhampton" },
                { value: "Worcester", label: "Worcester" },
                { value: "York", label: "York" }
               
              ]}
              onChange={(selectedOption) => formik.setFieldValue("city", selectedOption.value)}
              onBlur={formik.handleBlur}
              value={formik.values.city ? { value: formik.values.city, label: formik.values.city } : null}
              invalid={formik.touched.city && formik.errors.city}
            />

            {formik.touched.city && formik.errors.city && (

              <FormFeedback type="invalid">{formik.errors.city}</FormFeedback>
            )}
          </div>

          <div className="mb-3">
            <Label className="form-label">Service</Label>
            <Select
              name="service"
              options={[
                { value: "UK Registered Nurse", label: "UK Registered Nurse" },
                { value: "UK Unregistered Nurse", label: "UK Unregistered Nurse" },
                { value: "Healthcare", label: "Healthcare" },
                { value: "Housekeeping", label: "Housekeeping" },
                { value: "Nannies", label: "Nannies" },
                { value: "Babysitter", label: "Babysitter" },
               
              ]}
              onChange={(selectedOption) => {formik.setFieldValue("service", selectedOption.value), handleCheckService(selectedOption.value)}}
              onBlur={formik.handleBlur}
              value={formik.values.service ? { value: formik.values.service, label: formik.values.service } : null}
              invalid={formik.touched.service && formik.errors.service}
            />

            {formik.touched.service && formik.errors.service && (

              <FormFeedback type="invalid">{formik.errors.service}</FormFeedback>
            )}
          </div>

        

   {
    serviceCheck=="Healthcare" || serviceCheck=="UK Registered Nurse" || serviceCheck=="UK Unregistered Nurse"   ?  

    <div className="mb-3">
    <Label className="form-label">Patient Type</Label>
    <Select
      name="patient"
      options={[
        { value: "Adult", label: "Adult Patient" },
        { value: "Child", label: "Child Patient" },
       
      ]}
      onChange={(selectedOption) => formik.setFieldValue("patient", selectedOption.value)}
      onBlur={formik.handleBlur}
      value={formik.values.patient ? { value: formik.values.patient, label: formik.values.patient } : null}
      invalid={formik.touched.patient && formik.errors.patient}
    />

    {formik.touched.patient && formik.errors.patient && (

      <FormFeedback type="invalid">{formik.errors.patient}</FormFeedback>
    )}
  </div>
    
    
    : <></>
   }

          <div className="tw:none mt-4 d-grid">
            <button className=" btn btn-primary btn-block" type="submit">
              Filter
            </button>
          </div>

   </Form>



      </CardBody>
      </Card>


        </Col>
      
        <Col lg={9}>
   <Card className=''>
    <CardBody>






        <Modal size='lg' modalClassName="mt-[10%]" 
            isOpen={modal_center}
            toggle={() => {
                tog_center(x);
            }}
            style={modalStyles}
        >
              <div className="modal-content" style={contentStyles}>
            <div className="modal-header  ">
                <h5 className="modal-title mt-0">Your Friends</h5>
                <button
                    type="button"
                    onClick={() => {
                        setmodal_center(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-bod bg-slate-100 ">
                <FriendsModal job={friend_job} />
            </div>
            </div>
        </Modal>


        <div className="" data-bs-interval="3000">
     
            {
            // jobs döngümüz başladı.
            }
            {
            
            jobs && jobs.length !== 0 ? 
            jobs.map((job, key) => (
                                            
                // id ve ref konfetimiz için kullanıyoruz.
                <div key={key} className='relative ' id={key} ref={confetiRef} >

                    {
                    // confetti true ise ve keyimiz keyno eşit ise keyno  handleApply(job, true, key)   buradan gelir apply butonuna tıkladıgımzda dogal olarak döngüdeki key ile apple butonu aynı keydir.
                    } 
                    {confetti ? (keyno == key ? <div className="confettie-wrap w-full"  > <Confetti numberOfPieces={40} width={width} height={height} /> </div> : console.log("Verem Oldum")) : <></>}
                    <div className="bg-light p-3 d-flex mb-3 rounded">
                        <img src={`${PROFIL_IMG}${job?.client_image}`} alt="" className="avatar-sm rounded me-3" />
                        <div className="flex-grow-1">
                            <h5 className="font-size-15 mb-2"><a href="candidate-overview" className="text-body">{job.client_name}</a> <span className="badge badge-soft-success">{job.job_type}</span></h5>
                            <ul className="list-unstyled hstack gap-3 mb-0 flex-grow-1">
                                <li>
                                    <i className="bx bx-map align-middle"></i> {job.city} / {job.county}
                                </li>
                                <li>
                                    <i className="bx bx-money align-middle"></i> {job.fee.fee}£ hour / {job.fee.hour} <i className="bx bx-time align-middle"></i>
                                </li>
                                <li>
                                     {job.days} days working / {job.fee.type}
                                </li>
                            </ul>
                            <div>

                            </div>
                            <h5 className="font-size-15 mt-8"><a href="candidate-overview" className="text-body">{job.job_title}</a> </h5>

                            <div className="bg-light my-4 d-flex mb-3 rounded">
                            <div dangerouslySetInnerHTML={{ __html: job.job_about }}></div>
                            </div>
                            <Row>
                                <Col sm="2"></Col>
                                <Col sm="3"></Col>
                                <Col sm="7" className='flex justify-end'>

                                <div className="mt-4"><Link to={`/JobView/${job._id}`} className="btn btn-primary btn-sm mr-1 text-xs">View Details</Link></div>

                                   
                                        {
                                         // daha önce bu job başvuru yapılmış mı diye kontrol ediyoruz. Eğer id'si varsa job içerisindeki applications içerisinde buton renginmiz tıklanmış hali alıcaktır ilk koşul çalışır
                                            job.applications.find((item) => item.id === id)
                                            ?
                                            <div className="mt-4"><button className="btn btn-secondary btn-sm pl-2 pr-2  mr-1 text-xs justify-center flex"> <i className="bx bx-badge-check  text-xs"> </i>Apply</button></div>
                                            :
                                            // apply false ise ve daha önce bu job başvurulmamışsa görünecek buton
                                            (!apply ? <div className="mt-4"><button onClick={() => { handleApply(job, true, key) }} className="btn btn-success btn-sm pl-2 pr-2  mr-1 text-xs  justify-center flex">Apply</button> </div> : <></>)
                                      }



                                    { apply ? 
                                             (
                                              keyno === key ?
                                                            <div className="mt-4">
                                                            <button className="btn btn-secondary btn-sm pl-2 pr-2  mr-1 text-xs  justify-center flex">
                                                            <ThreeDots
                                                                height="16"
                                                                width="20"
                                                                radius="9"
                                                                color="#fff"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            /></button></div> 
                                                            : (!job.applications.find((item) => item.id === id) ? <div className="mt-4"><button onClick={() => { handleApply(job, true, key) }} className="btn btn-success btn-sm pl-2 pr-2  mr-1 text-xs  justify-center flex">Apply</button> </div> : <></>)
                                            )
                                          : 
                                          <></>}



                                     <div className="mt-4"><button onClick={() =>  tog_center(job)} className="btn btn-danger btn-sm pl-2 pr-2  mr-1 "><i className="mdi mdi-account-heart   text-xs"></i></button></div>



                                    {job.bookmark.find((item) => item.id === id) ? bookmarkfunc(job) : <div className="mt-4" key={key}>
                                        <button onClick={() => handleBookmark(job, true)} className="btn btn-secondary btn-sm pl-2 pr-2">
                                            <i className="bx bx-bookmark-plus text-xs"></i>
                                        </button>
                                    </div>}









                                </Col>
                            </Row>
                        </div>



               
                    </div>

                </div>

            )) : 
            
            <Row> 
            <Col className="flex flex-col items-center justify-start " >
            <div className="alert alert-warning fade show" role="alert">We haven't found an offer advert for you yet. Please visit us again.</div>
           
            <Col xl={6} sm={12}>
                <Lottie
                    animationData={catwaiting}
                    loop
                    autoplay={true}
                    renderer="svg" // İsteğe bağlı olarak svg veya canvas olarak belirleyebilirsiniz
                   
                />
            </Col>
            </Col>
            </Row>
            
            }




        </div>






    </CardBody>
</Card>

</Col>
</Row>

         </div>
         
        </Container>
      </div>
    </React.Fragment>
  );
};



export default Jobs;
