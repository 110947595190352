import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class TopBar extends Component {
  render() {
    return (
        <div className="d-flex flex-wrap gap-2 mb-3 justify-content-end">
        
        <button
          type="button"
          className="btn btn-soft-danger "
        >
          <i className="bx bxl-youtube font-size-16 align-middle me-2"></i>{" "}
          Watch Tutorial
        </button>
        <Link to="/ProfileEdit"
          type="button"
          className="btn btn-soft-success"
        >
          <i className="bx bx-edit font-size-16 align-middle me-2"></i>{" "}
          Edit Page
        </Link>



 
      </div>
    )
  }
}
