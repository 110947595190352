import React from "react";

import {
    Col,
    Container,

    Row,

} from "reactstrap";


import commnetsimg from "../../assets/images/my/commnets.gif";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";





const Comments = () => {

    //meta title
    document.title = "Commnets | Thewawy - Partner Admin Dashboard";




    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Thewawy" breadcrumbItem="Commnets" />

                    <Row>
                        <Col lg="12">
                            <div className=" flex flex-column justify-center items-center ">No any Comments...
                            
                            <img src={commnetsimg} alt="" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};



export default Comments;
