import React, { Component, useState } from 'react'
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
  } from "reactstrap";
  

  import Switch from "react-switch"
  import classnames from "classnames";
// Redux

import UkNurse from './Services/UkNurse';
import UkUnNurse from './Services/UkUnNurser';
import Healthcare from './Services/Healthcare';
import Housekeeping from './Services/Housekeeping';
import Nannies from './Services/Nannies';
import Babysitter from './Services/Babysitter';



const Tab_Services = props =>{

  const [activeTab, setactiveTab] = useState("10")


  const  toggle = (tab) => {
    if (activeTab !== tab) {
     setactiveTab(tab)
    }
  }

  return (
    <TabPane tabId="3" id="v-pills-confir" role="tabpanel">

         <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="h4">Services Settings & Edit</CardTitle>
                <p className="card-title-desc">
                You can add new services or add/remove existing services. If the current services do not suit you, please let us know the services you provide in the support section.
                </p>

                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "bg-danger":activeTab === "10",
                        "text-white":activeTab === "10",
                        active: activeTab === "10",
                      })}
                      onClick={() => {
                        toggle("10");
                      }}
                    >
                      UK Nurse
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "bg-danger":activeTab === "20",
                        "text-white":activeTab === "20",
                        active: activeTab === "20",
                      })}
                      onClick={() => {
                        toggle("20");
                      }}
                    >
                      UK Unregistered Nurse
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "bg-danger":activeTab === "30",
                        "text-white":activeTab === "30",
                        active: activeTab === "30",
                      })}
                      onClick={() => {
                        toggle("30");
                      }}
                    >
                      Healthcare
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "bg-danger":activeTab === "40",
                        "text-white":activeTab === "40",
                        active: activeTab === "40",
                      })}
                      onClick={() => {
                        toggle("40");
                      }}
                    >
                      Housekeeping
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "bg-danger":activeTab === "50",
                        "text-white":activeTab === "50",
                        active: activeTab === "50",
                      })}
                      onClick={() => {
                        toggle("50");
                      }}
                    >
                      Nannies
                    </NavLink>
                  </NavItem>
                  <NavItem>
                  <NavLink style={ { cursor: "pointer"}}
                      
                      className={classnames({
                        "bg-danger":activeTab === "60",
                        "text-white":activeTab === "60",
                        active: activeTab === "60",

                      })}
                      
                      onClick={() => {
                        toggle("60");
                      }}
                    >
                      Babysitter
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent
                  activeTab={activeTab}
                  className="p-3 text-muted"
                >
                  <TabPane tabId="10"> <UkNurse info={props.info} /></TabPane>
                  <TabPane tabId="20"> <UkUnNurse info={props.info} /></TabPane>
                  <TabPane tabId="30"> <Healthcare info={props.info} /></TabPane>
                  <TabPane tabId="40"> <Housekeeping info={props.info} /></TabPane>
                  <TabPane tabId="50"> <Nannies info={props.info} /></TabPane>
                  <TabPane tabId="60"> <Babysitter info={props.info} /></TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>

        
  </TabPane>
)

}

export default Tab_Services


