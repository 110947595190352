import React, { Component } from 'react'
import { Col, Card, CardBody, Row } from "reactstrap";

const Stats = ({info}) => {



const applyJob = info ? info.jobs.all_jobs.length : null;
const complatedIobs =info ? info.jobs.completed_jobs : null;

console.log("DATA : " ,info);

    return (
      
    <Row>
      <Col  xl="4">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium mb-2">
                      Applied Jobs
                    </p>
                    <h4 className="mb-0">{applyJob > 0 ? applyJob : 0 }  </h4>
                  </div>
  
                  <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                    <span className="avatar-title">
                      <i
                        className="bx bx-check-circle font-size-24"
                      />
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col  xl="4">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium mb-2">
                    Completed Jobs
                    </p>
                    <h4 className="mb-0">{complatedIobs > 0 ? complatedIobs : 0}</h4>
                  </div>
  
                  <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                    <span className="avatar-title">
                      <i
                        className="bx bx-hourglass font-size-24"
                      />
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col  xl="4">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium mb-2">
                    Profile Viewed
                    </p>
                    <h4 className="mb-0">{complatedIobs > 0 ? complatedIobs : 0}</h4>
                  </div>
  
                  <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                    <span className="avatar-title">
                      <i
                        className="bx bxs-user-detail font-size-24"
                      />
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
    </Row>

   
    )

 
}


export default Stats