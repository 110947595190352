import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  VERIFY_CODE,
  VERIFY_CHECK,
  RESENT_CODE,
  PASSWORD_FORGOT,
} from "./actionTypes"



export const verifyCode = (verifyData, history) => {
  const code = verifyData.code
  const phone = verifyData.phone
 
   return {
     type: VERIFY_CODE,
     payload: { code,phone,history },
   }
 }
 
 export const verifyCheck = (check, history) => {
   return {
     type: VERIFY_CHECK,
     payload: { check, history },
   }
 }
 export const resendCode = (phone, history) => {
   return {
     type: RESENT_CODE,
     payload: { phone, history },
   }
 }


export const registerPartner = (user, history) => {
  return {
    type: REGISTER_USER,
    payload:  { user, history }
  }
}

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const PasswordForgot = email => {
  return {
    type: PASSWORD_FORGOT,
    email,
  }
}
