import React from "react";

import {
    Col,
    Container,

    Row,

} from "reactstrap";


import friendimg from "../../assets/images/my/Friend.gif";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";





const Friends = () => {

    //meta title
    document.title = "Friends | Thewawy - Partner Admin Dashboard";




    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Thewawy" breadcrumbItem="Friends" />

                    <Row>
                        <Col lg="12">
                            <div className=" flex flex-column justify-center items-center ">No any friends...
                            
                            <img src={friendimg} alt="" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};



export default Friends;
