import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody ,CardTitle,Table} from "reactstrap";

import PropTypes from 'prop-types';


//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { useSelector } from "react-redux";


const Education = (props) =>{


 const dispatch = useDispatch();
 const {education} = props.info;



        return (
            <React.Fragment>
                <Col lg={12}>
                    <Card>
                        <CardBody>
   
                            <h5 className="mb-3">Education</h5>
                            <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                    <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <div>
                                                <h6 className="font-size-14 mb-1  text-danger">{education ?  education.highscholl.name : <></>}</h6>
                                                <p className="text-muted  text-primary"> Start and Finish Time : <span  className="font-size-12 mb-1 text-primary"> {education ?  `( ${education.highscholl.start} - ${education.highscholl.finish} )` : <></>}</span></p>


                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="event-list">
                                    <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <div>
                                                <h6 className="font-size-14 mb-1 text-danger">{education ?  education.university.university : <></>}</h6>
                                                Start and Finish Time :<span  className="font-size-12  text-primary"> {education ?  `( ${education.university.start} - ${education.university.finish} )` : <></>}</span>
                                                <p className="text-muted"> Departman : <span  className="font-size-12 mb-1 text-primary"> {education ?  education.university.name : <></>}</span></p>

                                            </div>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </CardBody>
                    </Card>




                </Col>
            </React.Fragment>
        );
   

}

export default Education;

